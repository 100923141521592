import React, { useContext } from "react";
import { IUser, apiRoutes } from "model";
import { useApiGetHelpers } from "hooks";

//Inspectlet
declare var __insp: any;
declare global {
  interface Window {
    __insp?: any;
    __inspld: any;
  }
}

const UserContext = React.createContext<[IUser | null, () => void, boolean] | undefined>(undefined);

export const UserProvider: React.FC = ({ children }) => {
  const context = useApiGetHelpers<IUser>(apiRoutes.userContext);
  return <UserContext.Provider value={context}>{children}</UserContext.Provider>;
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === undefined) throw new Error("User context is undefined");
  context && identifySession(context[0]);
  return context;
};



const identifySession = async (user: IUser | null) => {
  if (!user) return;
  if (user.status !== 0) return;

  window.__insp = window.__insp || [];
  __insp.push(["wid", 1875036543]);
  var ldinsp = function() {
    if (typeof window.__inspld != "undefined") return;
    window.__inspld = 1;
    var insp = document.createElement("script");
    insp.type = "text/javascript";
    insp.async = true;
    insp.id = "inspsync";
    insp.src =
      ("https:" === document.location.protocol ? "https" : "http") +
      "://cdn.inspectlet.com/inspectlet.js?wid=1875036543&r=" +
      Math.floor(new Date().getTime() / 3600000);
    var x = document.getElementsByTagName("script")[0];
    if (x.parentNode) x.parentNode.insertBefore(insp, x);
  };
  setTimeout(ldinsp, 0);

  // window.__insp.push(["identify", user.email]);
  __insp.push(["identify", user.email]);
};
