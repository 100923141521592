import React from "react";
import { useFormContext } from "react-hook-form";
import {
  FormField,
  FormField2,
  FormField2Sign,
  FormField3,
  FormField4,
  FormField2SignWith2Label,
  FormFieldNoLabel,
  FormField2SignWithlabelClassName,
} from "./form-fields";
import { ValidationOptions } from "react-hook-form/dist/types";

type FormInputProps = {
  type: string;
  mustMatch?: string;
  rule?: ValidationOptions;
};
type FormInputLabelProps = {
  type: string;
  label: string;
  label2?: string;
  mustMatch?: string;
  rule?: ValidationOptions;
};
type FormInputLabel2Props = {
  type: string;
  label: string;
  label2?: string;
  mustMatch?: string;
  rule?: ValidationOptions;
  labelClassName:string;
  labelClassName2:string;
  dollarSignLabel:string;
};
type FormInputlabelClassNameProps = {
  type: string;
  label: string;
  label2?: string;
  mustMatch?: string;
  rule?: ValidationOptions;
  labelClassName:string;
  dollarSignLabel:string;
};

export const FormInput = ({
  type,
  name,
  placeholder,
  rule,
  mustMatch,
  ...props
}: FormInputProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >) => {
  const { register, errors, getValues } = useFormContext();
  const inputName = name || type;
  const inputPlaceholder =
    placeholder || type.charAt(0).toUpperCase() + type.slice(1);

  if (mustMatch)
    rule = {
      ...rule,
      validate: () =>
        getValues()[inputName] === getValues()[mustMatch] || "must match",
    };

  return (
    <FormField error={errors[inputName] && errors[inputName]!.message}>
      <input
        className="input"
        type={type}
        name={inputName}
        placeholder={inputPlaceholder}
        ref={rule ? register(rule) : register}
        {...props}
      />
    </FormField>
  );
};
export const FormInput2 = ({
  type,
  name,
  placeholder,
  rule,
  mustMatch,
  ...props
}: FormInputProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >) => {
  const { register, errors, getValues } = useFormContext();
  const inputName = name || type;
  const inputPlaceholder =
    placeholder || type.charAt(0).toUpperCase() + type.slice(1);

  if (mustMatch)
    rule = {
      ...rule,
      validate: () =>
        getValues()[inputName] === getValues()[mustMatch] || "must match",
    };

  return (
    <FormField4 error={errors[inputName] && errors[inputName]!.message}>
      <input
        className="input"
        type={type}
        name={inputName}
        placeholder={inputPlaceholder}
        ref={rule ? register(rule) : register}
        {...props}
      />
    </FormField4>
  );
};
export const FormInputLabel = ({
  type,
  name,
  label,
  placeholder,
  rule,
  mustMatch,
  ...props
}: FormInputLabelProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >) => {
  const { register, errors, getValues } = useFormContext();
  const inputName = name || type;
  const inputPlaceholder =
    placeholder || type.charAt(0).toUpperCase() + type.slice(1);

  if (mustMatch)
    rule = {
      ...rule,
      validate: () =>
        getValues()[inputName] === getValues()[mustMatch] || "must match",
    };

  return (
    <FormField2
      error={errors[inputName] && errors[inputName]!.message}
      label={label}
    >
      <input
        className="input"
        type={type}
        name={inputName}
        placeholder={inputPlaceholder}
        ref={rule ? register(rule) : register}
        {...props}
      />
    </FormField2>
  );
};

export const FormInputLabel2 = ({
  type,
  name,
  label,
  placeholder,
  rule,
  mustMatch,
  ...props
}: FormInputLabelProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >) => {
  const { register, errors, getValues } = useFormContext();
  const inputName = name || type;
  const inputPlaceholder =
    placeholder || type.charAt(0).toUpperCase() + type.slice(1);

  if (mustMatch)
    rule = {
      ...rule,
      validate: () =>
        getValues()[inputName] === getValues()[mustMatch] || "must match",
    };

  return (
    <FormField3
      error={errors[inputName] && errors[inputName]!.message}
      label={label}
    >
      <input
        className="input"
        type={type}
        name={inputName}
        placeholder={inputPlaceholder}
        ref={rule ? register(rule) : register}
        {...props}
      />
    </FormField3>
  );
};
export const FormInputLabelDollarSignOutSide = ({
  type,
  name,
  label,
  placeholder,
  rule,
  mustMatch,
  ...props
}: FormInputLabelProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >) => {
  const { register, errors, getValues } = useFormContext();
  const inputName = name || type;
  const inputPlaceholder =
    placeholder || type.charAt(0).toUpperCase() + type.slice(1);

  if (mustMatch)
    rule = {
      ...rule,
      validate: () =>
        getValues()[inputName] === getValues()[mustMatch] || "must match",
    };
  return (
    <FormFieldNoLabel error={errors[inputName] && errors[inputName]!.message}>
      <span className="dollarOutside">$</span>
      <input
        className="input"
        type={type}
        name={inputName}
        placeholder={inputPlaceholder}
        ref={rule ? register(rule) : register}
        {...props}
      />
    </FormFieldNoLabel>
  );
};
export const FormInputLabelSign = ({
  type,
  name,
  label,
  placeholder,
  rule,
  mustMatch,
  ...props
}: FormInputLabelProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >) => {
  const { register, errors, getValues } = useFormContext();
  const inputName = name || type;
  const inputPlaceholder =
    placeholder || type.charAt(0).toUpperCase() + type.slice(1);

  if (mustMatch)
    rule = {
      ...rule,
      validate: () =>
        getValues()[inputName] === getValues()[mustMatch] || "must match",
    };
  return (
    <FormField2Sign
      error={errors[inputName] && errors[inputName]!.message}
      label={label}
    >
      <input
        className="input"
        type={type}
        name={inputName}
        placeholder={inputPlaceholder}
        ref={rule ? register(rule) : register}
        {...props}
      />
      <span className="icon is-left dollarSign">$</span>
    </FormField2Sign>
  );
};
export const FormInputlabelClassNameSign = ({
  type,
  name,
  label,
  placeholder,
  rule,
  mustMatch,
  dollarSignLabel,
  labelClassName,
  ...props
}: FormInputlabelClassNameProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >) => {
  const { register, errors, getValues } = useFormContext();
  const inputName = name || type;
  const inputPlaceholder =
    placeholder || type.charAt(0).toUpperCase() + type.slice(1);

  if (mustMatch)
    rule = {
      ...rule,
      validate: () =>
        getValues()[inputName] === getValues()[mustMatch] || "must match",
    };
  return (
    <FormField2SignWithlabelClassName
      error={errors[inputName] && errors[inputName]!.message}
      label={label} labelClassName={labelClassName}
    >
      <input
        className="input"
        type={type}
        name={inputName}
        placeholder={inputPlaceholder}
        ref={rule ? register(rule) : register}
        {...props}
      />
      {dollarSignLabel!==""&&<span className={`${dollarSignLabel} icon is-left`}>$</span>}
    </FormField2SignWithlabelClassName>
  );
};
export const FormInputLabelSignWith2Label = ({
  type,
  name,
  label,
  label2,
  dollarSignLabel,
  labelClassName,
  labelClassName2,
  placeholder,
  rule,
  mustMatch,
  ...props
}: FormInputLabel2Props &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >) => {
  const { register, errors, getValues } = useFormContext();
  const inputName = name || type;
  const inputPlaceholder =
    placeholder || type.charAt(0).toUpperCase() + type.slice(1);

  if (mustMatch)
    rule = {
      ...rule,
      validate: () =>
        getValues()[inputName] === getValues()[mustMatch] || "must match",
    };
  return (
    <FormField2SignWith2Label
      error={errors[inputName] && errors[inputName]!.message}
      label={label}
      label2={label2}
      labelClassName={labelClassName}
      labelClassName2={labelClassName2}
    >
      <input
        className="input"
        type={type}
        name={inputName}
        placeholder={inputPlaceholder}
        ref={rule ? register(rule) : register}
        {...props}
      />
       {dollarSignLabel!==""&&<span className={`${dollarSignLabel} icon is-left`}>$</span>}
    </FormField2SignWith2Label>
  );
};

export const FormTextarea = ({
  name,
  placeholder,
  rule,
}: {
  name: string;
  placeholder?: string;
  rule?: ValidationOptions;
}) => {
  const { register, errors } = useFormContext();

  return (
    <FormField error={errors[name] && errors[name]!.message}>
      <textarea
        className="textarea"
        ref={rule ? register(rule) : register}
        placeholder={placeholder}
        name={name}
      />
    </FormField>
  );
};
export const FormTextareaOrange = ({
  name,
  placeholder,
  rule,
}: {
  name: string;
  placeholder?: string;
  rule?: ValidationOptions;
}) => {
  const { register, errors } = useFormContext();

  return (
    <FormField error={errors[name] && errors[name]!.message}>
      <textarea
        id="textareaOragne"
        className="labelOrange"
        ref={rule ? register(rule) : register}
        placeholder={placeholder}
        name={name}
      />
    </FormField>
  );
};
