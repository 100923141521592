import React from "react";
import {  BreakFor1000 } from "components/shared/breakpoints";
import { DesktopNavbar } from "./nav-desktop";
import { MobileNavbar } from "./nav-mobile";
import { useUserContext } from "contexts";

export const Navbar = ({
  dark,
  absolute,
  NoPersonIcon,
  reverse
}: {
  dark?: boolean;
  absolute?: boolean;
  NoPersonIcon?:boolean;
  reverse?:boolean;
}) => {
  const [user] = useUserContext();

  const auth = user !== null && user.status === 0;
  const admin = user !== null && user.admin;
  if(user&&user.admin){
    localStorage.setItem("logout", "false");
  }else{
    localStorage.setItem("logout", "true");
  }

  return (
    <>
      <div className="desktopOnly">
        <DesktopNavbar
          dark={dark}
          auth={auth}
          absolute={absolute}
          admin={admin}
          reverse={reverse}
        />
      </div>
      <BreakFor1000>
        <MobileNavbar dark={dark} auth={auth} absolute={absolute} admin={admin} NoPersonIcon={NoPersonIcon} reverse={reverse} />
      </BreakFor1000>
    </>
  );
};

export default Navbar;
