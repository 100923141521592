import React, { useReducer, useContext } from "react";

const initialLocationState = {
  destination: "",
  invitationToken: "",
  redirect: false
}

const LocationContext = React.createContext<[Data, Dispatch] | undefined>(undefined);
type Data = { destination: string, invitationToken:string, redirect:boolean };
type Action = { type: "setLocation"; payload: { data: {destination:string} }}
            | { type: "resetLocation";}
            | { type: "resetInvitationToken";}
            | { type: "resetRedirect";}
            | { type: "setRedirect";  payload: { redirect: any }}
            | { type: "reset";}
            | { type: "setInvitationToken"; payload: { token: any }}

type Dispatch = (action: Action) => void;

const LocationReducer = (state: Data, action: Action) => {
  switch (action.type) {
    case "setLocation":
      return {...state, destination: action.payload.data.destination};
    case "resetLocation":
      return {...state, destination: ""};
    case "setRedirect":
      return {...state, redirect: action.payload.redirect};
    case "resetRedirect":
      return {...state, redirect: false};
    case "resetInvitationToken":
      return {...state, invitationToken: ""};
    case "reset":
      return {...state, invitationToken: "",  destination: "", redirect: false};
    case "setInvitationToken":
      return {...state, invitationToken: action.payload.token};
    default:
      throw new Error(`unknown action ${action}`);
  }
};

export const LocationProvider: React.FC = ({ children }) => {
  const [location, dispatch] = useReducer(LocationReducer, initialLocationState);
  return <LocationContext.Provider value={[location, dispatch]}>{children}</LocationContext.Provider>;
};

export const useLocationReducer = () => {
  const context = useContext(LocationContext);
  if (context === undefined) throw new Error("location context is undefined");
  return context;
};
