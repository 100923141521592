export const formatUsd = (pennies: number, fix?: number) =>
  `$${(pennies / 100).toFixed(fix === undefined ? 0 : fix)}`;

export const formatUsdVague = (pennies: number) => {};

export const strUSDWithComma = (dollar: number) => {
  let nfObject = new Intl.NumberFormat("en-US");
  let num = dollar / 100;
  let n = parseFloat(num.toFixed(2));
  let output = nfObject.format(n);
  return output;
};

export const strUSDWithCommaAndSign = (dollar: number) => {
  var dollarAmount = (dollar / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });

  if (dollarAmount.slice(-3) === ".00") {
    dollarAmount = dollarAmount.slice(0, -3);
  }

  return dollarAmount;
};
