import _ from "lodash";

export const months = [
  { name: "January", value: "Jan", code: 1 },
  { name: "February", value: "Feb", code: 2 },
  { name: "March", value: "Mar", code: 3 },
  { name: "April", value: "Apr", code: 4 },
  { name: "May", value: "May", code: 5 },
  { name: "June", value: "Jun", code: 6 },
  { name: "July", value: "Jul", code: 7 },
  { name: "August", value: "Aug", code: 8 },
  { name: "September", value: "Sep", code: 9 },
  { name: "October", value: "Oct", code: 10 },
  { name: "November", value: "Nov", code: 11 },
  { name: "December", value: "Dec", code: 12 }
];

const currentYear = new Date().getFullYear();
export const years = _.times(130, num => currentYear - num);
