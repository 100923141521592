import React from "react";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import { config } from "model";
import menuWhite from "../../../assets/svg/menuWhite.svg";
import menuBlue from "../../../assets/svg/menuBlue.svg";
import menuX from "../../../assets/svg/x.svg";
import userSignIn from "../../../assets/svg/userSignIn.svg";
import userSignInWhite from "../../../assets/svg/userSignInWhite.svg";
import logoStandard from "../../../assets/svg/liftRocketLogo2.svg";
import logoKnockout from "../../../assets/svg/liftRocketLogo2.svg";
import { useUserContext } from "contexts";
type mobileNavbarProps = {
  dark?: boolean;
  auth?: boolean;
  absolute?: boolean;
  admin: boolean;
  reverse?: boolean;
  NoPersonIcon?: boolean
};

export const MobileNavbar = ({
  dark,
  auth,
  absolute,
  admin,
  reverse,
  NoPersonIcon
}: mobileNavbarProps) => {

  const [user] = useUserContext();
  



  return (
    <div className="mainNavContainer">
      <div className="rightSide">
        <div style={{ width: "100%", display: "flex", justifyContent: "center", position: "absolute" }}>
          <Link className="headerItemImage" to="/" style={{ position: "absolute", marginTop: 4, display: "flex", justifyContent: "center", }}>
            <img
              src={dark ? logoStandard : logoKnockout}
              alt="header rocket"
              className="headerLogo"
              style={{ width: 75, height: 75, }}

            />
          </Link>

        </div>
        <div className="empty">
          <div
            className={reverse ? "burger-menu-wrapper mobileNav mobileNavWhite" : "burger-menu-wrapper mobileNavPrimary"}
            style={{ position: "absolute", right: 92 }}
          >
            <Menu
              right
              disableAutoFocus
              customBurgerIcon={<img src={reverse ? menuBlue : menuWhite} alt="=" />}
              customCrossIcon={<img src={menuX} alt="X" />}
            >
              <Link
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                }}
                to={"/"}
              >

              </Link>
              <hr />


              {auth ? (
                <>
                  <NewMobileNavLink to="/messages/inbox" text="INBOX" />
                  <NewMobileNavLink to="/messages/compose" text="CONTACT US" />
                  <NewMobileNavLink to="/faqIFrame" text="HELP CENTER" />
                  <NewMobileNavLink to="/profileSetting" text="SETTINGS" />
                  <NewMobileNavLink to="/liftDashboard" text="DASHBOARD" />

                  <NewMobileNavLink to="/logout" text="Log out" img="svg/xWhite.svg" />
                </>
              ) : (
                <>
                  <NewMobileNavLink
                    to="/login"
                    text={"Log in"}
                    img="svg/account_white.svg"
                  />
                  <MobileNavExternalLink
                    href={`${config.mainUrl}/ourstory`}
                    text="About us"

                  />
                  <MobileNavExternalLink
                    href={`${config.mainUrl}/?section=about`}
                    text="How it works"

                  />

                  <MobileNavExternalLink
                    href={`${config.mainUrl}/faq`}
                    text="FAQs"

                  />
                </>

              )}


              {/* <Link
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
            to={"/blog"}
          >
            Blog
          </Link> */}

            </Menu>
          </div>
        </div>

      </div>





      <div className={reverse ? "mobileNavWhite leftSide" : "mobileNavPrimary leftSide"} style={{ width: "70", paddingTop: 25, paddingLeft: 30, height: 83 }}>

        {NoPersonIcon ? <></> : <div id="signInHeader">

          <Link to={user && user.firstName ? "/liftDashboard" : user ? "/liftDashboard" : "/login"} className="" >
            <img
              src={reverse ? userSignIn : userSignInWhite}
              alt="header rocket"
              className="signInImage"
              style={{ marginLeft: 5 }}


            />
            <div className={reverse ? "signInTextPrimary" : "signInTextWhite"}> {user && user.firstName ? user.firstName: user&&user.status===8?"Sign in": ""}</div>

          </Link>
        </div>}




      </div>

    </div>
  );
};

export const OldMobileNavbar = ({
  dark,
  auth,
  absolute,
  admin,
}: mobileNavbarProps) => {
  const pubUrl = process.env.PUBLIC_URL;

  return (
    <>
      <Link to="/liftDashboard">
        <img
          src={`/icons/rocket_${dark ? "blue" : "white"}.svg`}
          style={{ width: 50, position: "absolute", right: 30, top: 30 }}
          alt="header"
        />
      </Link>
      <div
        className="burger-menu-wrapper"
        style={{ position: absolute ? "absolute" : "inherit" }}
      >
        <Menu
          disableAutoFocus
          customBurgerIcon={
            <img
              src={`${pubUrl}/svg/menu_${dark ? "blue" : "white"}.svg`}
              alt="="
            />
          }
          customCrossIcon={<img src={`${pubUrl}/svg/x.svg`} alt="X" />}
        >
          <Link
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
            to={auth ? "/liftDashboard" : "/"}
          >
            <img
              style={{ width: "60%", margin: "0px" }}
              src={`${pubUrl}/svg/Logo_knockout.svg`}
              alt="header rocket"
            />
          </Link>
          <hr />
          {auth ? (
            <>
              <MobileNavLink to="/messages/inbox" text="INBOX" />
              <MobileNavLink to="/messages/compose" text="CONTACT US" />
              <MobileNavLink to="/faqIFrame" text="HELP CENTER" />
              <MobileNavLink to="/profileSetting" text="SETTINGS" />
              <MobileNavLink to="/liftDashboard" text="DASHBOARD" />

              <MobileNavLink to="/logout" text="Log out" img="svg/xWhite.svg" />
            </>
          ) : (
            <MobileNavLink
              to="/login"
              text="Log in"
              img="svg/account_white.svg"
            />
          )}
        </Menu>
      </div>
    </>
  );
};

const MobileNavLink = ({
  to,
  text,
  img,
}: {
  to: string;
  text: string;
  img?: string;
}) => (
  <Link
    style={{ width: "100%", display: "flex", justifyContent: "center" }}
    className="mobileNavLinkSlide"
    to={to}
  >

    {text}
  </Link>
);
const MobileNavExternalLink = ({
  href,
  text,
  img,
}: {
  href: string;
  text: string;
  img?: string;
}) => (
  <a
    className="mobileNavLinkSlide"
    style={{ width: "100%", display: "flex", justifyContent: "center" }}
    href={href}
  >

    {text}
  </a>
);
const NewMobileNavLink = ({
  to,
  text,
  img,
}: {
  to: string;
  text: string;
  img?: string;
}) => (
  <Link className="menu-item button is-primary" to={to}>
    {img && (
      <span className="icon" >
        <img
          className="mobileNavIcon"
          src={`${process.env.PUBLIC_URL}/${img}`}
          alt="navicon"
        />
      </span>
    )}
    <span className="mobileNavLinkSlide" style={{ minWidth: 90 }}>{text}</span>
  </Link>
);
// const ExternalNavLink = ({
//   to,
//   text,
//   img,
// }: {
//   to: string;
//   text: string;
//   img?: string;
// }) => (
//   <a className="menu-item button is-primary" href={to}>
//     {img && (
//       <span className="icon">
//         <img
//           className="mobileNavIcon"
//           src={`${process.env.PUBLIC_URL}/${img}`}
//           alt="navicon"
//         />
//       </span>
//     )}
//     <span style={{ minWidth: 90 }}>{text}</span>
//   </a>
// );
