import React from "react";
import { useFormContext } from "react-hook-form";
import { ValidationOptions } from "react-hook-form/dist/types";
import { months, years } from "model";
import _ from "lodash";
import {
  FormField,
  FormField5,
  FormFieldWhite,
  FormFieldOrange,
  FormFieldOrangeNoLabel,
  FormFieldOrangeForPayment,
  FormFieldWithClassName
} from "./form-fields";
import { strUSDWithCommaAndSign } from "utilities";

export const FormSelectField: React.FC<{
  name: string;
  rule?: ValidationOptions;
  label?: string;
}> = ({ name, rule, label, children }) => {
  const { register, errors } = useFormContext();

  return (
    <FormField error={errors[name] && errors[name]!.message} label={label}>
      <div className="select is-white is-fullwidth">
        <select name={name} ref={rule ? register(rule) : register}>
          {children}
        </select>
      </div>
    </FormField>
  );
};
export const FormSelectFieldOrange: React.FC<{
  name: string;
  rule?: ValidationOptions;
  label?: string;
}> = ({ name, rule, label, children }) => {
  const { register, errors } = useFormContext();

  return (
    <FormFieldOrange
      error={errors[name] && errors[name]!.message}
      label={label}
    >
      <div className="select is-white is-fullwidth orangeFormField">
        <select name={name} ref={rule ? register(rule) : register}>
          {children}
        </select>
      </div>
    </FormFieldOrange>
  );
};
export const FormSelectFieldWithClassNames: React.FC<{
  name: string;
  rule?: ValidationOptions;
  label?: string;
  className:string;
  labelClassName:string;
}> = ({ name, rule, labelClassName, className, label, children }) => {
  const { register, errors } = useFormContext();

  return (
    <FormFieldWithClassName
      error={errors[name] && errors[name]!.message}
      label={label}
      labelClassName ={labelClassName}
    >
      <div className={`${className} select is-white is-fullwidth `}>
        <select name={name} ref={rule ? register(rule) : register}>
          {children}
        </select>
      </div>
    </FormFieldWithClassName>
  );
};
export const FormSelectFieldOrangeSmall: React.FC<{
  name: string;
  rule?: ValidationOptions;
  label?: string;
}> = ({ name, rule, label, children }) => {
  const { register, errors } = useFormContext();

  return (
    <FormFieldOrange
      error={errors[name] && errors[name]!.message}
      label={label}
    >
      <div className="select is-white is-fullwidth orangeFormFieldSmall">
        <select name={name} ref={rule ? register(rule) : register}>
          {children}
        </select>
      </div>
    </FormFieldOrange>
  );
};

export const FormSelectFieldOrangeMedium: React.FC<{
  name: string;
  rule?: ValidationOptions;
  label?: string;
}> = ({ name, rule, label, children }) => {
  const { register, errors } = useFormContext();

  return (
    <FormFieldOrange
      error={errors[name] && errors[name]!.message}
      label={label}
    >
      <div className="select is-white is-fullwidth orangeFormFieldMedium">
        <select name={name} ref={rule ? register(rule) : register}>
          {children}
        </select>
      </div>
    </FormFieldOrange>
  );
};

export const FormSelectFieldWithValue: React.FC<{
  name: string;
  rule?: ValidationOptions;
  label?: string;
}> = ({ name, rule, label, children }) => {
  const { register, errors } = useFormContext();

  return (
    <FormField error={errors[name] && errors[name]!.message} label={label}>
      <div className="select is-white is-fullwidth">
        <select name={name} ref={rule ? register(rule) : register}>
          {children}
        </select>
      </div>
    </FormField>
  );
};

export const FormRadio: React.FC<{
  name: string;
  value?: string;
  rule?: ValidationOptions;
}> = ({ name, rule, children, value }) => {
  const { register, errors } = useFormContext();

  return (
    <FormFieldOrangeNoLabel error={errors[name] && errors[name]!.message}>
      <input
        style={{ margin: "0px 4px" }}
        name={name}
        type="radio"
        value={value}
        ref={rule ? register(rule) : register}
      />
      {children}
    </FormFieldOrangeNoLabel>
  );
};

export const FormRadioYesNo: React.FC<{
  name: string;
  labelClassName:string;
  rule?: ValidationOptions;
  label?: string;
  
}> = ({ name, rule, label, labelClassName }) => {
  const { register, errors } = useFormContext();

  return (
    <FormFieldWithClassName
      error={errors[name] && errors[name]!.message}
      label={label}
      labelClassName = {labelClassName}
    >
      <div className="radioRow radioRowFirst">
        <input
          className="radioYesNoInput"
          style={{ margin: "-1px 4px" }}
          name={name}
          type="radio"
          value="yes"
          ref={rule ? register(rule) : register}
        />
        <div className="radioYesNo">Yes</div>
      </div>

      <div className="radioRow">
        <input
          className="radioYesNoInput"
          style={{ margin: "-1px 4px" }}
          name={name}
          type="radio"
          value="no"
          ref={rule ? register(rule) : register}
        />
        <div className="radioYesNo">No</div>
      </div>
    </FormFieldWithClassName>
  );
};

export const FormRadioSometimesNotatAll: React.FC<{
  name: string;
  rule?: ValidationOptions;
  label?: string;
  labelClassName:string;
}> = ({ name, rule, label, labelClassName }) => {
  const { register, errors } = useFormContext();

  return (
    <FormFieldWithClassName
      error={errors[name] && errors[name]!.message}
      label={label}
      labelClassName = {labelClassName}
    >
      
      <div className="radioRow radioRowFirst">
        <input
          className="radioSometimesNotatAllInput"
          style={{ margin: "8px 4px" }}
          name={name}
          type="radio"
          value="Some of the time"
          ref={rule ? register(rule) : register}
        />
        <div className="radioSometimesNotatAll">Some of the time</div>
      </div>

      {/* <br></br> */}
      <div className="radioRow">
        <input
          className="radioSometimesNotatAllInput"
          style={{ margin: "8px 4px" }}
          name={name}
          type="radio"
          value="Most of the time"
          ref={rule ? register(rule) : register}
        />
        <div className="radioSometimesNotatAll">Most of the time</div>
      </div>

      {/* <br></br> */}
      <div className="radioRow">
        <input
          className="radioSometimesNotatAllInput"
          style={{ margin: "8px 4px" }}
          name={name}
          type="radio"
          value="All of the time"
          ref={rule ? register(rule) : register}
        />
        <div className="radioSometimesNotatAll">All of the time</div>
      </div>
    </FormFieldWithClassName>
  );
};
export const FormRadioPaymentType: React.FC<{
  name: string;
  setOtherInput: Function;
  schedule: number;
  full: number;
  rule?: ValidationOptions;
  label?: string;
}> = ({ name, rule, label, setOtherInput, schedule, full }) => {
  const { register, errors } = useFormContext();
  const otherAction = (value: any) => {
    setOtherInput(value);
  };

  return (
    <FormFieldOrangeForPayment
      error={errors[name] && errors[name]!.message}
      label={label}
    >
      <div className="makePaymentSelect">
        <div className="is-flex makePaymentSelectMt makePaymentRadioSection">
          <input
            className="radioSometimesNotatAllInput"
            style={{ margin: "8px 4px" }}
            name={name}
            type="radio"
            value="scheduledPayment"
            onChange={() => otherAction(false)}
            ref={rule ? register(rule) : register}
          />
          <div className="radioSometimesNotatAll">
            <div>
             Scheduled payment 
            </div>
            <div className="makePaymentSelectDollar">
            {strUSDWithCommaAndSign(schedule)}
            </div>
           
          </div>
        </div>

        {/* <br></br> */}
        <div className="is-flex makePaymentRadioSection">
          <input
            className="radioSometimesNotatAllInput"
            style={{ margin: "8px 4px" }}
            name={name}
            type="radio"
            value="payInFull"
            onChange={() => otherAction(false)}
            ref={rule ? register(rule) : register}
          />
          <div className="radioSometimesNotatAll">
           
            <div>
            Pay in full 
            </div>
            <div className="makePaymentSelectDollar">
            {strUSDWithCommaAndSign(full)}
            </div>
          </div>
        </div>

        {/* <br></br> */}
        <div className="is-flex">
          <input
            className="radioSometimesNotatAllInput"
            style={{ margin: "8px 4px" }}
            name={name}
            type="radio"
            value="otherAmount"
            onChange={() => otherAction(true)}
            ref={rule ? register(rule) : register}
          />
          <div className="radioSometimesNotatAll">Other amount</div>
        </div>
      </div>
    </FormFieldOrangeForPayment>
  );
};
export const FormRadioPaymentMethod: React.FC<{
  name: string;
  rule?: ValidationOptions;
  label?: string;
}> = ({ name, rule, label }) => {
  const { register, errors } = useFormContext();

  return (
    <FormFieldOrangeForPayment
      error={errors[name] && errors[name]!.message}
      label={label}
    >
      <div className="makePaymentSelect">
        <div className="is-flex makePaymentSelectMt">
          <input
            className="radioSometimesNotatAllInput"
            style={{ margin: "8px 4px" }}
            name={name}
            type="radio"
            value="ach"
            ref={rule ? register(rule) : register}
          />
          <div className="radioSometimesNotatAll">Bank</div>
        </div>

        {/* <br></br> */}
        <div className="is-flex">
          <input
            className="radioSometimesNotatAllInput"
            style={{ margin: "8px 4px" }}
            name={name}
            type="radio"
            value="creditCard"
            ref={rule ? register(rule) : register}
          />
          <div className="radioSometimesNotatAll">Debit or Credit Card</div>
        </div>
      </div>
    </FormFieldOrangeForPayment>
  );
};

export const FormCheckBox: React.FC<{
  name: string;
  rule?: ValidationOptions;
}> = ({ name, rule, children }) => {
  const { register, errors } = useFormContext();

  return (
    <FormField error={errors[name] && errors[name]!.message}>
      <label className="checkbox">
        <input
          style={{ margin: "0px 4px" }}
          name={name}
          type="checkbox"
          ref={rule ? register(rule) : register}
        />
        {children}
      </label>
    </FormField>
  );
};

export const FormCheckBox2: React.FC<{
  name: string;
  rule?: ValidationOptions;
}> = ({ name, rule, children }) => {
  const { register, errors } = useFormContext();

  return (
    <FormField error={errors[name] && errors[name]!.message}>
      <div style={{ display: "flex" }}>
        <input
          style={{ margin: "4px 4px" }}
          name={name}
          type="checkbox"
          ref={rule ? register(rule) : register}
        />
        <label className="checkbox">{children}</label>
      </div>
    </FormField>
  );
};
export const FormCheckBox3: React.FC<{
  name: string;
  rule?: ValidationOptions;
}> = ({ name, rule, children }) => {
  const { register, errors } = useFormContext();

  return (
    <FormField5 error={errors[name] && errors[name]!.message}>
      <div style={{ display: "flex", justifyContent: "center" }} className="registerCheckbox">
        <input
          style={{ margin: "4px 4px" }}
          name={name}
          type="checkbox"
          ref={rule ? register(rule) : register}
        />
        <label className="checkbox registerCheckBoxLabel">{children}</label>
      </div>
    </FormField5>
  );
};
export const FormCheckBoxTerm: React.FC<{
  name: string;
  rule?: ValidationOptions;
  checkValue: boolean;
  checkAction: Function;
}> = ({ name, rule, children, checkValue, checkAction }) => {
  const { register, errors } = useFormContext();

  const checkActionChange = (checkValue: boolean) => {
    checkAction(!checkValue);
  };

  return (
    <FormField error={errors[name] && errors[name]!.message}>
      <label className="checkbox">
        <input
          style={{ margin: "0px 4px" }}
          name={name}
          type="checkbox"
          ref={rule ? register(rule) : register}
          checked={checkValue}
          onChange={() => checkActionChange(checkValue)}
        />
        {children}
      </label>
    </FormField>
  );
};

export const FormDateSelect: React.FC<{
  name: string;
  label?: string;
  labelClassName:string
}> = ({ name, label, labelClassName }) => {
  const { register } = useFormContext();

  return (
    <FormFieldWithClassName label={label} labelClassName ={labelClassName}>
      <div
        className="dateInfo"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div className="select is-primary">
          <select name={name + ".month"} ref={register}>
            {_.map(months, m => (
              <option key={m.value} value={m.code}>
                {m.name}
              </option>
            ))}
          </select>
        </div>
        <div className="select is-primary">
          <select name={name + ".day"} ref={register}>
            {_.times(31, n => (
              <option key={n} value={n + 1}>
                {n + 1}
              </option>
            ))}
          </select>
        </div>
        <div className="select is-primary">
          <select name={name + ".year"} ref={register}>
            {_.map(years, y => (
              <option key={y} value={y}>
                {y}
              </option>
            ))}
          </select>
        </div>
      </div>
    </FormFieldWithClassName>
  );
};

export const FormDateSelectWhite: React.FC<{
  name: string;
  label?: string;
}> = ({ name, label }) => {
  const { register } = useFormContext();

  return (
    <FormFieldWhite label={label}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div className="select is-white">
          <select name={name + ".month"} ref={register}>
            {_.map(months, m => (
              <option key={m.value} value={m.code}>
                {m.name}
              </option>
            ))}
          </select>
        </div>
        <div className="select is-white">
          <select name={name + ".day"} ref={register}>
            {_.times(31, n => (
              <option key={n} value={n + 1}>
                {n + 1}
              </option>
            ))}
          </select>
        </div>
        <div className="select is-white">
          <select name={name + ".year"} ref={register}>
            {_.map(years, y => (
              <option key={y} value={y}>
                {y}
              </option>
            ))}
          </select>
        </div>
      </div>
    </FormFieldWhite>
  );
};
