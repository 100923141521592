import React from "react";
import { Helmet } from "react-helmet";

const PrivacyPolicySectionHelmet = () => (
  <Helmet>
    <meta charSet="utf-8" />

    <title>The Financial Support System for Youth Sports.</title>
    <meta property="og:title" content="Privacy Policy | LiftRocket" />
    <meta name="twitter:title" content="Privacy Policy | LiftRocket" />

    <link rel="canonical" href="https://web3.liftrocket.org/privacypolicy" />
    <meta
      property="og:url"
      content="https://web3.liftrocket.org/privacypolicy"
    />
    <meta
      name="twitter:url"
      content="https://web3.liftrocket.org/privacypolicy"
    />
    {/*
    <meta name='description'         content="A Lift lets you meet life's needs without a bank, paperwork, or credit scores. LiftRocket allows you to borrow money when you need it the most." />
    <meta property='og:description'  content="A Lift lets you meet life's needs without a bank, paperwork, or credit scores. LiftRocket allows you to borrow money when you need it the most." />
    <meta name='twitter:description' content="A Lift lets you meet life's needs without a bank, paperwork, or credit scores. LiftRocket allows you to borrow money when you need it the most." />
    */}

    <link
      rel="icon"
      href="https://s3.us-east-2.amazonaws.com/liftrocket.org/liftRocket.png"
    />
    <meta
      property="og:image"
      content="https://s3.us-east-2.amazonaws.com/liftrocket.org/liftRocket.png"
    />
    <meta
      name="twitter:image"
      content="https://s3.us-east-2.amazonaws.com/liftrocket.org/liftRocket.png"
    />
  </Helmet>
);

export const PrivacyPolicySection = () => (
  <>
    <PrivacyPolicySectionHelmet />
    <section className="section">
      <div className="container content is-small privatePolicyMain">
        <h1 className="title is-3">LiftRocket Privacy Policy</h1>
        <div>
          <p>
            <b>Effective: September 6, 2023</b>
          </p>
          <div className="privatePolicyBody"></div>
          <ul>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <b>General.</b>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  This Privacy Policy applies when you access or use{" "}
                </span>
                <a href="https://web3.liftrocket.org/">
                  <span style={{ fontWeight: 400 }}>liftrocket</span>
                </a>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  or any other LiftRocket online service or mobile application
                  (collectively, the "Site"), which is owned or administered by
                  LiftRocket LLC. ("we", "us" and "our"). We, as the
                  administrator, respect the privacy of users ( collectively,
                  "you" or "your") and are committed to protecting the
                  information that we collect from you. This Privacy Policy
                  describes how we collect, store, use and protect information
                  that you provide, directly in connection with your use of the
                  Site or indirectly through third-parties, to obtain products
                  or services available through the Site. This Privacy Policy
                  also describes your choices regarding your information. We may
                  amend this Privacy Policy from time to time by posting a
                  revised, dated version on the Site. By visiting or using the
                  Site, you accept the practices described in this Privacy
                  Policy.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <b>Information Collected.</b>
                <span style={{ fontWeight: 400 }}>
                  &nbsp; In connection with your use of the Site, we collect,
                  directly or indirectly, three types of information:
                </span>
              </li>
              <ul>
                <li style={{ fontWeight: 400 }}>
                  <b>User Information:</b>
                  <span style={{ fontWeight: 400 }}>
                    {" "}
                    In connection with providing services, we may receive
                    information from users, including, but not limited to: name,
                    mailing and residential address, email address, telephone
                    number, bank account information, marketplace account
                    information, payment processing procedures, social network
                    data, shipping information, and if the user is a business,
                    business size, business type, business incorporation date,
                    accounting information and vendor service information. We
                    may also receive user Information that consists of
                    personally identifiable information, including, but not
                    limited to: the individual’s (or if a business, the
                    principal’s) name, mailing address, email address, telephone
                    number, date of birth, social security number, and estimated
                    FICO score. We collect user Information from you when you
                    (i) register on the Site, (ii) fill out forms or fields on
                    the Site, (iii) complete an application for a product or
                    service available through the Site or (iv) communicate with
                    us by email, mail, text, telephone, facsimile or other
                    electronic means. During the course of providing services,
                    we may collect additional or user Information from third
                    parties, including, but not limited to, referral partners,
                    identity verification services or credit bureaus.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }}>
                  <b>General Information. </b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;We collect general information from your computer,
                    mobile device or other access device when you use the Site
                    (including downloading and using a mobile application or
                    accessing a mobile optimized site), view content about the
                    Site on a third-party website or open emails or links in
                    emails from us. This general information includes, but is
                    not limited to, information about (i) your Internet
                    connection; (ii) the equipment you use to access the Site
                    and usage details; (ii) your operating system, browser
                    version and IP address; (iii) your mobile device type, your
                    device’s unique identifier and your mobile network
                    information and (iv) web pages or advertisements that you
                    view and information that you search for on the Site. As is
                    true of most websites, we gather certain information
                    automatically and store it in log files. This information
                    may include Internet protocol (IP) addresses, browser type,
                    internet service provider (ISP), referring/exit pages,
                    operating system, date/time stamp, and/or clickstream data.
                    We may link this automatically collected data to other
                    information we collect about you. We and our business
                    partners, affiliates, or analytics or service providers use
                    cookies or similar technologies to analyze trends,
                    administer the website, track users’ movements around the
                    website, and to gather demographic information about our
                    user base, as a whole. We may receive reports based on the
                    use of these technologies by such companies on an individual
                    and aggregated basis. We also use analytics software to
                    allow us to better understand the functionality of our
                    mobile application. This software may record information
                    such as how often you use the application, the events that
                    occur within the application, aggregated usage, performance
                    data, and where the application was downloaded from.&nbsp;
                  </span>
                </li>
              </ul>
              <li style={{ fontWeight: 400 }}>
                <b>Use of Information.</b>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  One of our primary purposes in collecting user information,
                  and general information from the Site is to establish a safe,
                  smooth, efficient and customized experience that allows
                  website and mobile users to take full advantage of the
                  products and services available through the Site. We use the
                  information collected through the Site to:
                </span>
              </li>
              <ul>
                <li style={{ fontWeight: 400 }}>
                  <span style={{ fontWeight: 400 }}>
                    Enable users to obtain products and services through the
                    Site;
                  </span>
                </li>
                <li style={{ fontWeight: 400 }}>
                  <span style={{ fontWeight: 400 }}>
                    Facilitate activities and transactions that occur in
                    connection with marketing, fundraising, underwriting,
                    lending, servicing and collections;
                  </span>
                </li>
                <li style={{ fontWeight: 400 }}>
                  <span style={{ fontWeight: 400 }}>
                    Send notifications and information regarding the Site and
                    products or services available through the Site;
                  </span>
                </li>
                <li style={{ fontWeight: 400 }}>
                  <span style={{ fontWeight: 400 }}>
                    Provide customer support;
                  </span>
                </li>
                <li style={{ fontWeight: 400 }}>
                  <span style={{ fontWeight: 400 }}>
                    Protect against potentially prohibited or illegal
                    activities, including fraud;
                  </span>
                </li>
                <li style={{ fontWeight: 400 }}>
                  <span style={{ fontWeight: 400 }}>
                    Fulfill legal requirements;
                  </span>
                </li>
                <li style={{ fontWeight: 400 }}>
                  <span style={{ fontWeight: 400 }}>
                    Customize and enhance user experience;
                  </span>
                </li>
                <li style={{ fontWeight: 400 }}>
                  <span style={{ fontWeight: 400 }}>
                    Provide targeted marketing and advertising;
                  </span>
                </li>
                <li style={{ fontWeight: 400 }}>
                  <span style={{ fontWeight: 400 }}>
                    Measure the performance of and improve the Site or mobile
                    application functionality;
                  </span>
                </li>
                <li style={{ fontWeight: 400 }}>
                  <span style={{ fontWeight: 400 }}>
                    Manage and protect our information technology
                    infrastructure;
                  </span>
                </li>
                <li style={{ fontWeight: 400 }}>
                  <span style={{ fontWeight: 400 }}>
                    Administer and manage the security of the Site;
                  </span>
                </li>
                <li style={{ fontWeight: 400 }}>
                  <span style={{ fontWeight: 400 }}>
                    Compile, use, research, analyze, and generate data analytics
                    reports to better understand our customers
                  </span>
                </li>
              </ul>
            </ul>
          </ul>
          
          <ul>
            <li>
              <b>
                <b>Posting of information.&nbsp; </b>
                <span style={{ fontWeight: 400 }}>
                  Some LiftRocket services require us to ask for and display on
                  the LiftRocket site information and/or images from users in
                  order to fulfill user requests.&nbsp; Users grant LiftRocket
                  the right to use, display and share such information with
                  other users or third parties as needed to complete the
                  delivery of services.&nbsp;&nbsp;
                </span>
              </b>
            </li>
          </ul>
          
          <ul>
            <li style={{ fontWeight: 400 }}>
              <b>Information Sharing.</b>
              <span style={{ fontWeight: 400 }}>
                &nbsp; We take your privacy seriously and will only share
                information as described in this Privacy Policy.
              </span>
            </li>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  We may share, whether aggregated or not, user information with
                  business partners, service providers, and other third parties
                  under the following circumstances:
                </span>
              </li>
            </ul>
          </ul>
          <ol>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                To support our business operations including, but not limited
                to, Site customer services, fraud prevention, secure data
                storage, and other similar services;
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                To a buyer or other successor in the event of a merger,
                divestiture, restructuring, reorganization, dissolution or other
                transfer of all or a portion of our business or an operating
                unit;
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                To fulfill the purpose for which you provide such information to
                us or any other purpose disclosed by us when you provide the
                information to us;
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                To any third party with your consent;
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                To protect the confidentiality or security of your records, to
                protect against or prevent actual or potential fraud,
                unauthorized transactions, claims or other liability, or for
                resolving disputes or inquiries;
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                To comply with federal, state or local laws, rules and other
                applicable legal requirements, to comply with properly
                authorized civil, criminal or regulatory investigations,
                subpoenas, summons, bankruptcy notices by federal, state or
                local authorities, or to respond to judicial process or
                government regulatory authorities that have jurisdiction over us
                for examination, compliance or other purposes as authorized by
                law; and
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                To the extent permitted or required under other provisions of
                laws to law enforcement, the Federal Trade Commission or
                self-regulatory organizations for an investigation related to
                public safety.
              </span>
            </li>
          </ol>
          <ul>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  We may share, whether aggregated or not, user information with
                  our business partners, including for jointly offered products
                  and services, under agreements which restrict our business
                  partners’ use of the information and require business partners
                  to follow policies regarding privacy and safeguarding of user
                  Information. Business partners may use such information
                  obtained from us to market their or our products or services
                  to you, including jointly offered products or services.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  We may also use, sell, license or distribute general
                  information (i.e., non-personally identifiable information) or
                  personally identifiable user information that has been
                  anonymized so that the information does not identify a
                  specific user without restriction, including, but not limited
                  to for producing data analytics and reports for business
                  partners or others.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  We may notify you via email or a prominent notice on the Site
                  of any change in ownership of user Information (including as a
                  result of a merger, acquisition, or sale of all or
                  substantially all of its assets), changes to this Privacy
                  Policy, as well as changes to any choices you may have
                  regarding your personally identifiable information.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  We may partner with a third-party ad network to either display
                  advertising on our Web site or to manage our advertising on
                  other sites. Our ad network partner may use cookies and Web
                  beacons to collect non-personally identifiable information
                  about your activities on this and other Web sites to provide
                  you targeted advertising based upon your interests. If we use
                  Google AdSense to publish ads on this site, when you view or
                  click on an ad a cookie will be set to help better provide
                  advertisements that may be of interest to you on this and
                  other Web sites. You may opt-out of the use of this cookie by
                  visiting Google’s Advertising and Privacy page:
                </span>
                <a href="http://www.google.com/privacy_ads.html">
                  <span style={{ fontWeight: 400 }}>
                    http://www.google.com/privacy_ads.html
                  </span>
                </a>
              </li>
            </ul>
            <li style={{ fontWeight: 400 }}>
              <b>Your Choices.</b>
              <span style={{ fontWeight: 400 }}>
                &nbsp; Providing information regarding your browsing and
                application activity is voluntary. Most computer systems and
                browsers offer their own privacy settings. You can adjust your
                privacy settings to disable the cookies used on the Site. If you
                disable cookies, you can still use the Site, but your ability to
                use some features of the Site may be limited. Most mobile phones
                allow you to control privacy settings, including location-based
                services by accessing the device’s setting’s menu. If you have
                questions about the privacy settings on your mobile device, you
                should contact your mobile service carrier. LiftRocket, its
                affiliates or its business partners may contact you to offer you
                products and services that may be of interest to you. Out of
                respect for your privacy, you may choose to stop receiving our
                newsletter or marketing emails, telephone solicitations or other
                forms of contact from LiftRocket, our affiliates, and our
                trusted business partners by following the unsubscribe
                instructions included in these emails or by contacting us at{" "}
              </span>
              <a href="mailto:member.services@liftrocket.com">
                <span style={{ fontWeight: 400 }}>
                  member.services@LiftRocket.com
                </span>
              </a>
              <span style={{ fontWeight: 400 }}>
                . We will respond to your request within a reasonable timeframe.
                If you no longer wish to receive marketing notifications through
                the LiftRocket
              </span>{" "}
              <span style={{ fontWeight: 400 }}>
                mobile application, you can adjust your device’s privacy
                preferences by visiting the settings page of the application.
                Opting out of newsletter or marketing emails or notifications
                will not stop any non-marketing emails or notifications related
                to the Site’s functionality or the products or services
                available through the Site.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <b>Third Party Information Collection. </b>
              <span style={{ fontWeight: 400 }}>
                &nbsp;We may partner with third parties to display
                advertisements on the Site and manage advertisements on other
                websites or mobile applications.&nbsp;
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <b>Links to Third Party Websites.</b>
              <span style={{ fontWeight: 400 }}>
                &nbsp; The Site may include links to other websites or mobile
                applications whose privacy practices may differ from ours. We
                are not responsible for any practices employed on third-party
                websites or applications, including the information and content
                contained on the website or application. If you submit
                information to a third-party website or application, your
                information is governed by the third party’s privacy policy. We
                encourage you to carefully read the privacy policy of any
                website or application you visit, access or use.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <b>Security of Information.</b>
              <span style={{ fontWeight: 400 }}>
                {" "}
                We protect user information by using reasonable physical,
                electronic and procedural safeguards in compliance with
                applicable federal and state regulations. We use computer
                safeguards such as firewalls and data encryption using secure
                socket layer technology (SSL). We also limit access to user
                information to employees who need the information to fulfill
                their job responsibilities. Such employees are provided access
                to user information only to the extent necessary to complete
                their job duties. We store and process information using
                third-party servers located in secure data centers in the United
                States. If you have any questions about the security of user
                information, you can contact us at the address at the bottom of
                this policy.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <b>Blog.</b>
              <span style={{ fontWeight: 400 }}>
                &nbsp; Our blog may be managed by a third party that may require
                you to register to post a comment. We do not have access or
                control of the information posted to the blog. You will need to
                contact the third party if you want information that was posted
                to the comments section removed. To learn about how the third
                party uses your information, please review its privacy policy.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <b>Testimonials.</b>
              <span style={{ fontWeight: 400 }}>
                &nbsp; We may display testimonials of members of LiftRocket on
                the Site in addition to other endorsements. With your consent,
                we may, post your testimonial along with your name and picture.
                If you wish to update or delete your testimonial, you can
                contact us at the address at the bottom of this policy.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <b>Invitations.</b>
              <span style={{ fontWeight: 400 }}>
                &nbsp; If you choose to invite others to visit or join
                LiftRocket, we will ask you for the individual’s email address
                or mobile phone number. We will automatically send an email or
                text, inviting the recipient to visit the Site. We store
                information about the individual for the purpose of sending an
                email and tracking the success of the invitation program. We may
                send additional emails, unless the recipient opts out of
                receiving such emails. The recipient may contact us at the
                address at the bottom of this policy to request that we remove
                its information from our database.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <b>Minors Under Age 18.</b>
              <span style={{ fontWeight: 400 }}>
                &nbsp; This Site and products or services available through this
                Site are not intended for minors less than eighteen years old.
                We do not knowingly solicit data online from or market online to
                anyone under the age of 18. If we knowingly receive a minor’s
                personally identifiable information, we will immediately delete
                it from our system.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <b>Social Media Widgets.</b>
              <span style={{ fontWeight: 400 }}>
                &nbsp; The Site may include social media features, such as the
                Facebook "Like" button and widgets, or interactive mini-programs
                that run on the Site. These features may collect your IP
                address, which page you are visiting on our website or
                application, and may set a cookie to enable the feature to
                function properly. Social media features and widgets are either
                hosted by a third party or hosted directly on the Site. Your
                interactions with these features are governed by the privacy
                policy of the company providing it.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <b>Data Retention.</b>
              <span style={{ fontWeight: 400 }}>
                {" "}
                We will retain your information for as long as the information
                is needed to provide you Site services. If you wish to request
                that we no longer use your information to provide you Site
                services, you may contact us at the address at the bottom of
                this policy. We will nonetheless retain your information to the
                extent that we deem necessary to comply with our legal or
                contractual obligations, resolve disputes or for other business
                purposes.
              </span>
            </li>
          </ul>
          <p>
            <b>Changes to Privacy Policy. </b>
            <span style={{ fontWeight: 400 }}>
              &nbsp;LiftRocket LLC reserves the right to change this policy from
              time to time to accommodate changes in technologies, laws, and
              user needs. In the event we modify this Privacy Policy, we will
              communicate these changes to you by posting them on this website
              and/or by notifying you via email prior to the change becoming
              effective in the event of any substantive or material changes. We
              encourage you to periodically review this page for the latest
              information on our privacy practices. Any questions or concerns
              should be addressed to:{" "}
            </span>
            <a href="mailto:member.services@liftrocket.com">
              <span style={{ fontWeight: 400 }}>
                member.services@LiftRocket.com
              </span>
            </a>
          </p>
        </div>
      </div>
    </section>
  </>
);
