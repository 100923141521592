import React from "react";
import {
  UserProvider,
  CartProvider,
  LocationProvider,
  LiftDashboardProvider,
  LiftDashboardBoosterProvider,
  LiftReviewProvider,
} from "contexts";

export const GlobalProvider: React.FC = ({ children }) => (
  <UserProvider>
    <CartProvider>
      <LocationProvider>
        <LiftDashboardProvider>
          <LiftDashboardBoosterProvider>
            <LiftReviewProvider>{children}</LiftReviewProvider>
          </LiftDashboardBoosterProvider>
        </LiftDashboardProvider>
      </LocationProvider>
    </CartProvider>
  </UserProvider>
);

/* <TransmitterProvider>{children}</TransmitterProvider> */
