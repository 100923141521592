import React from "react";
import { Link } from "react-router-dom";
import qs from "query-string";
import { useLocation } from "react-router";

export const XLink = ({ to }: { to?: string }) => {
  const location = useLocation();
  var { xlink } = qs.parse(location.search);
  if (typeof xlink !== "string") xlink = null;
  return (
    <div style={{ position: "absolute", right: 50, top: 30 }}>
      <Link to={xlink || to || "/"}>
        <img src={`${process.env.PUBLIC_URL}/svg/x.svg`} alt="x" />
      </Link>
    </div>
  );
};
export const XLinkWhite = ({ to }: { to?: string }) => {
  const location = useLocation();
  var { xlink } = qs.parse(location.search);
  if (typeof xlink !== "string") xlink = null;
  return (
    <div style={{ position: "absolute", right: 50, top: 30 }}>
      <Link to={xlink || to || "/"}>
        <img src={`${process.env.PUBLIC_URL}/svg/xWhite.svg`} alt="x" />
      </Link>
    </div>
  );
};

export const ExternalXLink = ({ to }: { to: string }) => {
  const location = useLocation();
  var { xlink } = qs.parse(location.search);
  if (typeof xlink !== "string") xlink = null;
  return (
    <div style={{ position: "absolute", right: 50, top: 30 }}>
      <a href={to}>
        <img src={`${process.env.PUBLIC_URL}/svg/x.svg`} alt="x" />
      </a>
    </div>
  );
};

export const ExternalXLinkWhite = ({ to }: { to: string }) => {
  const location = useLocation();
  var { xlink } = qs.parse(location.search);
  if (typeof xlink !== "string") xlink = null;
  return (
    <div style={{ position: "absolute", right: 50, top: 30 }}>
      <a href={to}>
        <img src={`${process.env.PUBLIC_URL}/svg/xWhite.svg`} alt="x" />
      </a>
    </div>
  );
};
