import React from "react";
import { Link } from "react-router-dom";
import { config } from "model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faUser } from "@fortawesome/free-solid-svg-icons";
import { useUserContext } from "contexts";
import logoStandard from "../../../assets/svg/liftRocketLogo2.svg";
type DesktopNavbarProps = {
  dark?: boolean;
  absolute?: boolean;
  auth?: boolean;
  admin?: boolean;
  reverse?:boolean;
};

export const DesktopNavbar = ({
  dark,
  auth,
  absolute,
  admin,
  reverse
}: DesktopNavbarProps) => {
  const [user] = useUserContext();

  return (
    <div
      className={`navbar is-primary pb-0 pt-0 ${reverse? "backgroundPrimary":"backgroundWhite"}`}
      role="navigation"
      aria-label="main navigation"
      style={{
        position: absolute ? "absolute" : "inherit",
        background: "none",
        width: "100vw",
      }}
    >
      <div
        className={` container navContainer has-text-${dark ? "primary" : "white"
          }`}
      >
        <div className="navbar-brand">
          <Link to={auth ? "/liftDashboard" : "/"}>
            <img
              src={logoStandard}
              className="headerLogo"
              alt="header rocket"
             
              style={{ padding: 10, marginTop: reverse?"24px":"0px", marginLeft: "20px", width: reverse?158:130, height: reverse?158:130 }}
            />
          </Link>
        </div>
        <div id="lr-navbar" className="navbar-menu">
          <div
            className={`navbar-end has-button-border-${dark ? "primary" : "white"
              }`}
          >
            <div className="buttons">
              {/* {admin && (
                <div
                  className={`navbar-item is-tab ${
                    dark ? "" : "has-text-white"
                  }`}
                >
                  <Link to={"/admin"}>
                    <div className="navItemFont">ADMIN</div>
                  </Link>
                </div>
              )} */}

              {auth && (
                <div
                  className={`navbar-item is-tab ${dark ? "" : "has-text-white"
                    }`}
                >
                  <Link to={"/liftDashboard"}>
                    <div className={`navItemFont ${reverse?"colorWhite":"colorPrimary"}`}>Dashboard</div>
                  </Link>
                </div>
              )}

              {auth&& <div
                className={`navbar-item is-tab ${dark ? "" : "has-text-white"}`}
              >
                <a href={`${config.mainUrl}/faqIFrame`} className={`navItemFont ${reverse?"colorWhite":"colorPrimary"}`}>
                  Help center
                </a>
              </div>}

              {auth && (
                <div
                  className={`navbar-item is-tab ${dark ? "" : "has-text-white"
                    }`}
                >
                  <Link to={"/messages/inbox"}>
                    <div className={`navItemFont ${reverse?"colorWhite":"colorPrimary"}`}>Inbox</div>
                  </Link>
                </div>
              )}

              {auth && (
                <div
                  className={`navbar-item is-tab ${dark ? "" : "has-text-white"
                    }`}
                >
                  <Link to={"/profileSetting"}>
                    <div className={`navItemFont ${reverse?"colorWhite":"colorPrimary"}`}>Settings</div>
                  </Link>
                </div>
              )}

              {auth && (
                <div
                  className={`navbar-item is-tab ${dark ? "" : "has-text-white"
                    }`}
                >
                  <Link to={"/messages/compose"}>
                    <div className={`navItemFont ${reverse?"colorWhite":"colorPrimary"}`}>Contact us</div>
                  </Link>
                </div>
              )}

             

              {auth?<></>:<><a href={`${config.mainUrl}/ourstory`}>
                <div className="navItemFontOld">About us</div>
              </a>
              <a href={`${config.mainUrl}/?section=about`}>
                <div className="navItemFontOld">How it works</div>
              </a>
              <a href={`${config.mainUrl}/faq`}>
                <div className="navItemFontOld">FAQs</div>
              </a>
              </>}


              
              

              {auth ? (
                <div className="navMainUserBox">
                  <Link to={"/liftDashboard"}>
                    <div className="navUserBox">
                      <div className="navPersonImage">
                        <FontAwesomeIcon
                          icon={reverse?faUser:faUserCircle}
                          color={` ${reverse?"white":"#4d6177"}`}
                          className="personIconUser"
                        />
                      </div>

                      <div className={`navName ${reverse?"colorWhite":"colorPrimary"}`}>{user && user.firstName}</div>
                    </div>
                  </Link>
                  <Link to={"/logout"}>
                    
                      <button className="button signOutDesktopBtn">Sign out</button>
                    
                  </Link>
                </div>
              ) : (
                <div className="navUserBtn">
                  <div className="navbar-item">
                    <NavLinkPrimary to="/register" text="Sign up" />
                  </div>
                  <div className="navbar-item">
                    <NavLinkPrimary to="/login" text="Sign in" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// const NavLink = ({
//   to,
//   text,
//   img,
// }: {
//   to: string;
//   text?: string;
//   img?: string;
// }) => (
//   <Link
//     to={to}
//     className="button is-primary is-rounded"
//     style={{ border: "2px solid", padding: "0px 20px" }}
//   >
//     {img && (
//       <span className="icon">
//         <img src={`${process.env.PUBLIC_URL}/${img}`} alt="navicon" />
//       </span>
//     )}
//     {text && <span>{text}</span>}
//   </Link>
// );

export const NavLinkRed = ({
  to,
  text,
  img,
}: {
  to: string;
  text?: string;
  img?: string;
}) => (
  <Link
    to={to}
    className="button whitetext is-danger is-rounded borderLessBtn"
    style={{ border: "2px solid", padding: "0px 20px" }}
  >
    {img && (
      <span className="icon">
        <img src={`${process.env.PUBLIC_URL}/${img}`} alt="navicon" />
      </span>
    )}
    {text && <span>{text}</span>}
  </Link>
);

export const NavLinkLightGrey = ({
  to,
  text,
  img,
}: {
  to: string;
  text?: string;
  img?: string;
}) => (
  <Link
    to={to}
    className="button lightgrey is-rounded borderLessBtn"
    style={{ padding: "0px 20px" }}
  >
    {img && (
      <span className="icon">
        <img src={`${process.env.PUBLIC_URL}/${img}`} alt="navicon" />
      </span>
    )}
    {text && <span>{text}</span>}
  </Link>
);
export const NavLinkPrimary = ({
  to,
  text,
  img,
}: {
  to: string;
  text?: string;
  img?: string;
}) => (
  <Link
    to={to}
    className="button headerBtn"

  >
    {img && (
      <span className="icon">
        <img src={`${process.env.PUBLIC_URL}/${img}`} alt="navicon" />
      </span>
    )}
    {text && <span>{text}</span>}
  </Link>
);
// type HoverProps = {
//   admin?: boolean;
// };
// const Hover = ({ admin }: HoverProps) => (
//   <div
//     className="navbar-item has-dropdown is-hoverable button is-primary is-rounded "
//     style={{ border: "2px solid" }}
//   >
//     <p
//       className="navbar-link is-rounded"
//       style={{ borderRadius: "0px 22px 22px 0px" }}
//     ></p>

//     <div className="navbar-dropdown is-boxed is-right is-active">
//       <a
//         href={`${config.mainUrl}/about`}
//         className="navbar-item nav-text-space"
//       >
//         How It Works
//       </a>
//       <a href={`${config.mainUrl}/faq`} className="navbar-item nav-text-space">
//         FAQS
//       </a>
//       <a href={`${config.mainUrl}/blog`} className="navbar-item nav-text-space">
//         Blog
//       </a>
//       <hr className="navbar-divider" />
//       <Link className="navbar-item" to="/logout">
//         Logout
//       </Link>
//       {admin && (
//         <div>
//           <hr className="navbar-divider" />
//           <Link to="/admin" className="dropdown-item">
//             Admin Tools
//           </Link>
//           {/* <p
//             className="btn-link dropdown-item"
//             onClick={() =>
//               httpGet<API & { link: { url: string } }>(
//                 apiRoutes.stripeLink
//               ).then(r => {
//                 if (r.status === 0) window.location.href = r.link.url;
//               })
//             }
//           >
//             Stripe Link
//           </p> */}
//         </div>
//       )}
//     </div>
//   </div>
// );
