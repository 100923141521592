import React from "react";
import { FriendSearchProps } from "model";
import { useThrottle } from "hooks";
import { FormInput } from "./form-text";
import { useFormContext } from "react-hook-form";
import _ from "lodash";

export const FriendSearch: React.FC<FriendSearchProps> = ({
  children,
  searchList,
  size,
  placeholder,
  name,
}) => {
  const { watch, setValue } = useFormContext();

  const searchTerm = useThrottle(watch(name), 300);

  const searchFunction = () =>
    !searchTerm
      ? []
      : _.chain(searchList)
          .filter(
            f =>
              f.email !== searchTerm &&
              _.values(f).some(g =>
                g
                  .toString()
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
              )
          )
          .slice(0, size || 5)
          .value();

  const filterSearch = React.useMemo(searchFunction, [searchTerm, searchList]);

  return (
    <div
      className={`dropdown ${_.isEmpty(filterSearch) ? "" : "is-active"}`}
      style={{ width: "100%" }}
    >
      <div className="dropdown-trigger" style={{ width: "100%" }}>
        {children ? (
          children
        ) : (
          <FormInput
            type="text"
            name={name}
            placeholder={placeholder ? placeholder : "Send To"}
          />
        )}
      </div>
      <div
        className="dropdown-menu"
        id="dropdown-menu"
        role="menu"
        style={{ width: "100%" }}
      >
        <div className="dropdown-content">
          {_.map(filterSearch, (s, i) => (
            <div
              id={"opton " + i}
              key={s.email}
              className="dropdown-item btn-link"
              onClick={() => setValue(name, s.email)}
            >
              <p>
                {_.isEmpty(s.firstName)
                  ? s.email
                  : `${s.firstName} ${s.lastName}`}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
