import React from "react";

export const LegalSection = () => (
  // <div className="has-background-primary has-text-white">
  <section className="section">
    <div className="container content is-small">
      <h1 className="title is-3">Terms of Use</h1>
      <div style={{ fontWeight: 400 }}>
        <p>
          <b>(Last revised June 1, 2021)</b>
        </p>
        <p>
          The following terms of use are a legal agreement (the "Agreement")
          between you ("you", "your", or "user") and LiftRocket LLC, its
          subsidiaries, affiliates, agents and assigns ("LiftRocket", "we",
          "us", or "our") that sets forth the terms and conditions for your use
          of LiftRocket's website located at [<u>www.LiftRocket.com</u>] and all
          affiliated websites (collectively, the "Site"), and/or the products
          and services offered, operated or made available by LiftRocket,
          including but not limited to its mobile applications (collectively,
          the "Services").
        </p>
        <p>
          The Site and Services are owned and operated by LiftRocket. This Site
          and Services are being provided to you expressly subject to this
          Agreement. By accessing, browsing and/or using the Site or the
          Services, you acknowledge that you have read, understood, and agree to
          be bound by the Agreement of this Agreement and to comply with all
          applicable laws and regulations.
        </p>
        <p>
          <strong>
            THIS AGREEMENT ALSO INCLUDES AMONG OTHER THINGS, A BINDING
            ARBITRATION PROVISION THAT CONTAINS A CLASS ACTION WAIVER. PLEASE
            REFER TO SECTION 18 BELOW FOR MORE INFORMATION.
          </strong>
        </p>
        <p></p>
        <ol>
          <li>
            <p>Acceptance of Agreement</p>
            <p>
              Please carefully review this Agreement before using this Site or
              Services or accessing any data thereon. This Agreement represents
              an agreement between you and LiftRocket, and contains the
              Agreement and conditions governing your use of and access to the
              Site and the Services. 
              <strong>
                If you do not agree to this Agreement, you may not access or use
                the Site or the Services.
              </strong>
               Please print out a copy of the Agreement of use for your records.
            </p>
          </li>
          <li>
            <p>Eligibility</p>
            <p>To use the Site or the Services and to accept the Agreement:</p>
            <ul>
              <li>You must be a US citizen or a legal US resident;</li>
              <li>
                You must be at least eighteen (18) years old or the age of
                majority in your jurisdiction; and
              </li>
              <li>
                You must not be prohibited by law from using the Site or the
                Services.
              </li>
              <p>
                To use the Services, you must also meet both of the following
                criteria:
              </p>
              <ul>
                <li>
                  You must have a checking account with one of our supported
                  banks or credit unions (a "Bank Account"); and
                </li>
              </ul>
              <li>
                Your Bank Account must enable our third-party data aggregators
                to view your transactions for the last ninety (90) days.
              </li>
              <p>Note: LiftRocket does not support joint Bank Accounts.</p>
            </ul>
          </li>
          <li>
            <p>Modification of This Agreement</p>
            <p>
              LiftRocket reserves the right to modify this Agreement at any time
              and will notify you of any such changes by posting the revised
              Agreement on the Site. You should check this Agreement on the Site
              periodically for changes. All changes shall be effective upon
              posting. We will date the Agreement with the last day of revision.
            </p>
            <p>
              Your continued use of the Site or the Services after any change to
              this Agreement signifies your agreement to be bound by any such
              changes. LiftRocket may terminate, suspend, change, or restrict
              access to all or any part of this Site or the Services without
              notice or liability.
            </p>
          </li>
          <li>
            <p>
              LiftRocket User Account Registration and User Information Updates
            </p>
            <p>
              To access and use the Services, you must create an account (a
              "LiftRocket User Account") and complete the registration process.
              This process will require you to create a login ID and a password
              or personal identification number ("PIN").
            </p>
            <p>
              When you sign up for the Services, you agree to provide truthful,
              accurate, current and complete information, such as your name,
              email address, and mobile phone number ("Account Information").
              You further represent that you are a legal owner of, and that you
              are authorized to provide us with, all Account Information and
              other information necessary to facilitate your use of the
              Services.
            </p>
            <p>
              You agree to update LiftRocket in the event that the Account
              Information provided by you changes, and LiftRocket will not be
              held liable for any errors or fees that occur as a result of
              outdated Account Information.
            </p>
            <p>
              Should any of your Account Information change, you agree that you
              will update this information as soon as possible. To update your
              Account Information, you may go to the Profile section of the
              LiftRocket app, click on "Settings," and update your Account
              Information accordingly.
            </p>
            <p>
              Should you believe or have reason to believe that any of your
              Account Information, including your email, phone number, and/or
              PIN, has been compromised, or that another person is accessing
              your LiftRocket User Account through some other means, you agree
              to notify us as soon as possible at 
              <u>member.services@LiftRocket.com</u>.
            </p>
          </li>
          <li>
            <p>Privacy Policy</p>
            <p>
              Your privacy is important to LiftRocket. The company highly values
              and respects the privacy of the personal information we obtain
              from you when you use the Site or the Services. For details on how
              we handle and protect data, LiftRocket maintains a 
              <u>Privacy Policy</u>. We reserve the right to update the Privacy
              Policy at our discretion, and that any changes made to our Privacy
              Policy are effective upon posting.
            </p>
          </li>
          <li>
            <p>Third-Party Account Information</p>
            <p>
              To use the Services, you must also direct LiftRocket to retrieve
              your account transaction history, balance information, and/or
              other information maintained by third-parties with which you have
              relationships, maintain accounts or engage in financial
              transactions ("Third-Party Account Information").
            </p>
            <p>
              LiftRocket works with one or more third-party service providers,
              to access this Third-Party Account Information. By using the
              Services, you license and authorize LiftRocket to access this
              information maintained by identified third parties, on your behalf
              as your agent, and you expressly authorize such third parties to
              disclose your information to us. By agreeing to this Agreement,
              you are also agreeing that you are responsible for keeping your
              passwords and usernames for this Third-Party Account Information
              secure, and for keeping those passwords and usernames up to date
              in the Services.
            </p>
            <p>
              You acknowledge that any Third-Party Account Information that is
              displayed through the Services will be the information we most
              recently accessed, and that this information may not reflect
              pending transactions or other recent activity.
            </p>
          </li>
          <li>
            <p>LiftRocket's Services & Fees</p>

            <p>7.1. Overview of LiftRocket's Services</p>
            <p>
              LiftRocket offers Lifts to its users. A Lift is our way of
              providing fast funding to help LiftRocket members meet emergency
              financial needs. A Lift requires answering a few questions and
              hooking up a bank account. We encourage you to add a Booster to
              improve your chances of receiving a Lift and increasing the amount
              you can receive. A Booster agrees to help make payments on the
              requester’s Pay It Forward plan in the event the requester is
              unable to complete a payment.
            </p>
            <p>7.2. Lift Features</p>
            <p>
              A Lift is an advance of funds to meet a requester’s emergency
              financial needs. Lifts are disbursed after evaluation of
              information provided by the requester and the requester’s booster,
              including, but not limited to:
            </p>
            <ul>
              <li>
                Information provided by the requester directly to LiftRocket
                through its website or app
              </li>
              <li>
                Information from the requester’s bank account provided by
                permission of the requester
              </li>
              <li>
                Information provided by the booster directly to LiftRocket
                through its website or app
              </li>
              <li>
                Information from the booster’s bank account provided by
                permission of the booster
              </li>
            </ul>
            <p>
              We ask requesters to pay an amount equal to the original advance
              plus fees (described below) through a payment schedule called a
              Pay It Forward plan. Payments are scheduled automatically through
              electronic payments from the requester’s bank account. Payments
              are currently collected on a bi-weekly basis (once every two
              weeks) on a regular schedule established by LiftRocket when Lifts
              are disbursed.
            </p>
            <p>
              If the requester is unable to complete an automatic payment,
              LiftRocket may ask the booster to make the payment.{" "}
            </p>
            <p>
              LiftRocket may allow boosters to complete their commitment to make
              payments on behalf of the requester after a defined period of
              successful consecutive payments from the requester. That period is
              currently six months.
            </p>
            <p>
              LiftRocket may allow requesters to qualify for a larger Lift or a
              new Lift based on their record of completing Pay It Forward
              payments successfully.{" "}
            </p>
            <p>7.3. Lift fees</p>
            <p>Bi-weekly Pay It Forward payments include three components</p>

            <table className="table table-body-background-color is-bordered liftDashboardQuestionRowTableInfo">
              <tr>
                <td>Balance repayment</td>
                <td>
                  return the original balance of your Lift in small, easy to
                  manage pieces.
                </td>
                <td>Dependent on the size of the Lift</td>
              </tr>
              <tr>
                <td>Service fee</td>
                <td>help to pay for LiftRocket’s operating expenses</td>
                <td>Currently $3.00 per payment</td>
              </tr>
              <tr>
                <td>Community contribution</td>
                <td>
                  Help us serve more members and fill gaps if members need extra
                  help
                </td>
                <td>Dependent on the size of the Lift</td>
              </tr>
            </table>

            <p>
              You can find all of your Pay It Forward details, including payment
              amounts, on your Lift dashboard.
            </p>
            <p>
              7.4. Credit and Debit Authorization For Pay It Forward payments
            </p>
            <p>
              We require that requesters link a Bank Account or debit card (a
              "Payment Method") for payment of the Pay It Forward plan. We will
              automatically charge your Payment Method for the Pay It Forward
              amount every two weeks on a schedule established when your Lift is
              awarded. Automatic payments will continue until you complete your
              Pay It Forward plan or you pay your remaining Lift balance in full
              through one or more manual payments. Upcoming dates upon which you
              will be charged for subsequent Membership Fees will also be
              displayed in the app.
            </p>
            <p>
              LiftRocket will do its best to collect the Pay It Forward payment
              when you have the funds available to pay it. If our attempt to
              collect the Pay It Forward payment is declined, LiftRocket
              reserves the right to try up to two (2) additional times, for a
              total of three (3) debit attempts. After the first decline,
              LiftRocket will inform you and provide an opportunity for you to
              initiate a manual payment. If you are unable to initiate and
              complete a manual payment, we will ask your booster to make the
              payment on your behalf.{" "}
            </p>
            <p>
              LiftRocket monitors your balance and does its best effort to
              ensure you have sufficient funds before debiting your account, but
              the company makes no warranties that an overdraft will not occur.
            </p>
            <p>
              We also require that boosters link a Bank Account or debit card (a
              "Payment Method") for payment of the Pay It Forward plan in the
              event a requester is unable to complete a payment. We will do our
              best to collect the Pay It Forward plan from the requester but if
              we are unable to do so, we will inform the booster and then charge
              the booster’s Payment Method for the uncollected amount.
            </p>
            <p>
              You acknowledge that, as applicable, the electronic authorization
              contained in this Section represents your written authorization
              for automated clearinghouse ("ACH") transactions as provided
              herein and will remain in full force and effect until you notify
              LiftRocket that you wish to revoke this authorization by emailing 
              <u>member.services@LiftRocket.com</u>. You must notify LiftRocket
              at least three (3) business days before the scheduled debit date
              in order to cancel this authorization. When you call or email,
              please include the name and telephone number associated with your
              LiftRocket User Account. Failure to provide correct and complete
              information may make it impossible for LiftRocket to stop
              withdrawal of the preauthorized withdrawal.
            </p>
            <p>
              You agree to indemnify and hold harmless LiftRocket from and
              against any loss incurred as a result of its withdrawal of a
              preauthorized debit transaction from your Bank Account or debit
              card if any of the information relied upon in your request to stop
              payment is incorrect or incomplete. If you have followed the
              instructions in this section to notify LiftRocket of your desire
              to revoke your authorization at least three (3) business days
              before the scheduled debit date, LiftRocket will be liable for
              your losses or damages directly caused by our failure to stop any
              preauthorized transaction. If we do not receive notice at least
              three (3) business days before the scheduled debit date, we may
              attempt, in our sole discretion, to cancel the transaction.
              However, we assume no responsibility for our failure to do so.
            </p>
            <p>
              You warrant and represent to LiftRocket that you have the right to
              authorize us to charge and credit your Bank Account or debit card
              for payments due to us under this Agreement.
            </p>
            <p>
              You represent that you are capable of saving or otherwise storing
              a copy of this electronic authorization for your records, and the
              credit and debit transactions you request comply with applicable
              law.
            </p>
            <p>7.5. Credit reporting</p>
            <p>
              LiftRocket does not currently report information about Lifts to
              credit reporting agencies.
            </p>
            <p>7.6. LiftRocket's Recourse In the Event of Non-Payment</p>
            <p>
              This Section sets forth the entirety of LiftRocket's recourse
              against requesters or boosters in the event that a Pay it Forward
              plan is not fully completed. Any other recourse or remedies
              claimed by LiftRocket, including but not limited to, indemnities,
              limitations on liability, and disclaimers of warranty described in
              this Agreement, do not apply to non-completion of a Pay It Forward
              plan.
            </p>
            <p>
              LiftRocket warrants that it has no legal or contractual claim
              against requesters or boosters based on a failure to complete a
              Pay It Forward plan, but LiftRocket will suspend your access to
              the Services and eligibility for additional Lifts until you
              complete the Pay It Forward plan in full. With respect to a
              failure to complete the plan in full, LiftRocket warrants it will
              not engage in any debt collection activities, place the amount
              owed with or sell to a third party for the purpose of debt
              collection activities, or report you to a consumer reporting
              agency. LiftRocket does not waive any rights regarding fraudulent
              activity, and LiftRocket will pursue instances of fraud.
            </p>
            <p>
              It is your responsibility to contact LiftRocket if you are unable
              to meet your Pay It Forward plan payment on the scheduled
              due-date. LiftRocket monitors your Bank Account balance before
              withdrawing the funds to ensure there are sufficient funds, but
              LiftRocket is not responsible for any overdraft fees,
              over-the-limit fees, or insufficient fund charges (including
              finance charges, late fees, or similar charges) that result from
              your failure to maintain a balance or available credit in your
              Bank Account sufficient to make a Pay It Forward plan payment.
            </p>
            <p>7.7. California Residents</p>
            <p>
              LiftRocket is not currently licensed by the Department of
              Financial Protection and Innovation. If the Department does decide
              in the future to license LiftRocket, and/or require LiftRocket
              make modifications to this agreement, such developments may have
              no impact on your obligations under this agreement.
            </p>
            <p>
              Although LiftRocket is not licensed by the Department, any
              consumer is invited to share any comment and concerns about
              LiftRocket or its product and practices with the Department of
              Financial Protection and Innovation at (866) 275-2677 (toll-free)
              or at the following URL: https://dfpi.ca.gov/file-a-complaint/.
            </p>
          </li>
          <li>
            <p>Membership & Cash Rewards</p>
            <p>
              LiftRocket may, from time to time, offer rewards programs. The
              specific terms of any such rewards program will be displayed to
              you through the Services. LiftRocket reserves the right to cancel,
              modify or terminate any rewards program at any time.
            </p>
          </li>
          <li>
            <p>Termination</p>
            <p>
              We, at our sole discretion, reserve the right to terminate this
              Agreement, terminate or suspend access to the Service, or
              terminate or suspend your LiftRocket User Account at any time for
              any reason. We will make reasonable efforts to notify you of any
              such suspension or termination unless we terminate your access in
              accordance with Section 10.2.
            </p>
            <p>
              You may also terminate this Agreement by deactivating your
              LiftRocket User Account at any time by emailing 
              <u>member.services@LiftRocket.com</u>. If we terminate this
              Agreement, terminate or suspend your access to the Services, or
              terminate or suspend your LiftRocket User Account, we will not be
              liable to you or any third party for compensation, reimbursement,
              or damages for any termination or suspension of our services, or
              for deletion of your information or account data.
            </p>
          </li>
          <li>
            <p>Your Representations and Warranties</p>
            <p>
              10.1. By using the Site or the Services, you represent and warrant
              all of the following:
            </p>
            <ul>
              <li>
                <strong>Documentation</strong>. You have the right and authority
                to submit or otherwise provide us with any documents for the
                provision of the services, including but not limited to pay
                stubs, or bank statements, related to your financial situation;
              </li>
              <li>
                <strong>Use of Joint Accounts Prohibited</strong>. Your linked
                Bank Checking Account, is not a joint account ("Joint Account").
                Joint Account means any account that is held by more than one
                person, with each person having the right to deposit into or
                withdraw funds from the account;
              </li>
              <li>
                <strong>
                  Violation of Any Law, Regulation, or Other Obligation
                </strong>
                . Your use of the Services does not violate or promote the
                violation of any applicable law or regulation or any legal or
                contractual obligation you may have to a third party, and you
                have and will at all times comply with all applicable laws,
                rules and regulations in connection with your use of the
                Services;
              </li>
              <li>
                <strong>Location of Financial Accounts</strong>. Any Bank
                Account(s) you associated with the Services are located in the
                United States;
              </li>
              <li>
                <strong>Third Party Access</strong>. You shall not share your
                LiftRocket User Account or login information with any third
                party, nor let any third party access your LiftRocket User
                Account, and are responsible for maintaining the confidentiality
                of the login information for your LiftRocket User Account. You
                are fully and solely responsible for the security of your
                computer system, mobile device and all activity on your
                LiftRocket User Account, even if such activities were not
                committed by you; and
              </li>
              <li>
                <strong>Use of Bank Checking Account</strong>. You will not link
                your Bank Checking Account to more than one LiftRocket User
                Account
              </li>
            </ul>
            <p>10.2. Verification of Your Representations and Warranties.</p>
            <p>
              By using the Services, you understand and agree that LiftRocket,
              through any identification verification methods it employs, may in
              its sole discretion, but is not required to, verify that any or
              all of your representations and warranties listed above are met.
              If you become aware of any violation or possible violation of your
              or another user's representations and warranties listed above, you
              will report it to LiftRocket. If LiftRocket becomes aware of any
              violation or possible violation of this Agreement, it may suspend
              and/or terminate your LiftRocket User Account with or without
              notice in its sole discretion.
            </p>
          </li>
          <li>
            <p>Disclaimer of Warranties</p>
            <p>
              The Site and the Services are provided on an "as is" and "as
              available" basis without warranties of any kind and does not
              guarantee that it will be free of defects. To the extent permitted
              by law, we disclaim all implied or statutory warranties of any
              kind relating to the Site or the Services, including, but not
              limited to, each prediction, and in no event shall LiftRocket or
              any of its officers, directors, shareholders, advisors, employees,
              agents, anyone acting on LiftRocket's behalf or affiliates be
              liable to you for any direct, indirect, incidental, special,
              punitive, or consequential damages, arising out of or in
              connection with your use of the Service, including any such
              Prediction or non-Prediction, whether or not the damages are
              foreseeable and whether or not LiftRocket has been advised of the
              possibility of such damages.
            </p>
            <p>
              If you are a California resident, you hereby waive California
              Civil Code section 1542 which provides: "A general release does
              not extend to claims that the creditor or releasing party does not
              know or suspect to exist in his or her favor at the time of
              executing the release and that, if known by him or her, would have
              materially affected his or her settlement with the debtor or
              released party."
            </p>
            <p>
              No advice or information, whether oral or written, obtained by you
              from LiftRocket, shall create any warranty not expressly stated in
              this Agreement. If you choose to rely on such information, you do
              so solely at your own risk.
            </p>
          </li>
          <li>
            <p>LiftRocket is Not a Financial Adviser</p>
            <p>
              Before using the Services, you should consider obtaining
              additional information and advice from a financial adviser.
              LiftRocket is neither a financial adviser nor a financial planner.
              We do not make any representations, warranties, or guarantees of
              any kind that the Services are appropriate for you.
            </p>
          </li>
          <li>
            <p>Prohibited Activities</p>
            <p>
              You are solely responsible for compliance with all laws, rules,
              and regulations that may apply to your use of the Site or the
              Services. In connection with your use of the Site or the Services,
              you shall not, and shall not assist or enable any third party, to:
            </p>
            <ul>
              <li>
                reverse engineer or attempt to find the underlying code of the
                Site and/or the Services;
              </li>
              <li>
                breach or circumvent any applicable laws or regulations,
                agreements with third-parties, third-party rights, or this
                Agreement;
              </li>
              <li>
                copy, modify, or create derivative works of the Site, the
                Services or Content (defined below) of any of the Site or the
                Services;
              </li>
              <li>
                attempt to disable or circumvent any security or access control
                mechanism of the Site or the Services;
              </li>
              <li>
                design or assist in designing cheats, exploits, automation
                software, such as, but not limited to, spiders, bots, hacks,
                mods or any other unauthorized third-party software to modify or
                interfere with the Site or the Services;
              </li>
              <li>
                attempt to gain unauthorized access to the Site or the Services,
                other LiftRocket User Accounts, or other devices, computer
                system, phone systems, or networks connected to the Site or the
                Services;
              </li>
              <li>harvest or otherwise collect information about users;</li>
              <li>
                use, display, mirror or frame the Site, the Services, Content or
                any individual element within the Site or the Services,
                LiftRocket's name, any LiftRocket Trademark (defined below), or
                the layout and design of any page or form contained on a page in
                the Site or the Services, without LiftRocket's express written
                consent;
              </li>
              <p>
                <strong>or</strong>
              </p>
              <li>
                take any action that damages or adversely affects, or could
                damage or adversely affect the performance or proper functioning
                of the Site or the Services.
              </li>
            </ul>
            <p>
              In the event you partake in any prohibited activities, you
              acknowledge and agree that LiftRocket has the right to and may, at
              its sole discretion and without notifying you, take legal and/or
              use other remedies including but not limited to suspending your
              LiftRocket User Account.
            </p>
          </li>
          <li>
            <p>Intellectual Property Rights (IP Rights)</p>
            <p>14.1. IP Rights: Content</p>
            <p>
              With the exception of any third party materials, LiftRocket is the
              owner of all worldwide rights, titles and interests in: (a) the
              Site and the Services, including all content therein, all design,
              text, graphics, images, audio, video and other material, as well
              as the domain name, tagline, organization and use look-and-feel,
              related documentation, and all enhancements, derivatives, bug
              fixes or improvements to the Site and the Services; and (b) trade
              names, trademarks, and logos of LiftRocket ((a) and (b)
              collectively, the "Content"). You will not use, copy, adapt,
              modify, prepare derivative works of, distribute, license, sell,
              transfer, publicly display, publicly perform, transmit, broadcast
              or otherwise exploit the Content without LiftRocket's written
              consent. You may, however, from time to time, download and/or
              print one copy of individual pages of the Site for your personal,
              non-commercial use, provided that you do not modify the Content
              and that you keep intact all copyright and other proprietary
              notices. You agree that you do not acquire any ownership rights in
              any downloaded Content. This permission terminates immediately if
              you breach this Agreement. You may not "mirror" any Content on the
              Site or in the Services without LiftRocket's express written
              permission. Any unauthorized use of the Content may violate
              copyright laws, trademark laws, the laws of privacy and publicity
              and/or communications regulations and statutes.
            </p>
            <p>14.2. IP Rights: Trademarks</p>
            <p>
              The trademarks, service marks and logos (the "Trademarks") used
              and displayed on the Site and in the Services are registered and
              unregistered Trademarks of LiftRocket. Other trademarks, service
              marks and trade names may be owned by others. Nothing on the Site
              or in the Services should be construed as granting, by
              implication, estoppel or otherwise, any license or right to use
              any Trademark or any other LiftRocket intellectual property
              displayed on the Site or in the Services. The names "LiftRocket"
              and "Bridge IT, Inc." and any other Trademarks shall not be used
              in any way, including in advertising or publicity pertaining to
              distribution of materials on the Site or in the Services, without
              prior written permission from LiftRocket.
            </p>
          </li>
          <li>
            <p>Links to Third Parties and Third Party-Content</p>
            <p>
              The Site or the Services may contain links or other content
              related to websites, advertisers, products and/or services offered
              by third parties. LiftRocket has no control and makes no
              representation with respect to any such links, content, websites,
              products or services or any information provided or transmitted
              via such links, websites, products or services, or otherwise
              provided by any such third party. You understand and agree that
              use of such links and use of any products or services from third
              parties is at your own risk. LiftRocket expressly disclaims, and
              user expressly releases LiftRocket from, any and all liability
              whatsoever for any controversies, claims, suits, injuries, loss,
              harm and/or damages, arising from and/or in any way related to the
              use of such links or the use of any such products or services from
              third parties.
            </p>
          </li>
          <li>
            <p>Limitation of Liability</p>
            <p>
              Under no circumstances will LiftRocket, or any of its successors,
              parents, subsidiaries, affiliates, officers, directors,
              stockholders, investors, employees, agents, representatives,
              attorneys and their respective heirs, successors and assigns (the
              "LiftRocket Parties") be liable for any damages, including direct,
              incidental, punitive, special, consequential or exemplary damages
              that directly or indirectly result from the use of, or the
              inability to use, the Site or the Services or the information
              contained on the Site or the Services, including for viruses
              alleged to have been obtained from the Site or the Services, even
              if LiftRocket has been advised of the possibility of such damages.
              In no event shall the LiftRocket Parties' total liability to you
              for all damages, losses, and causes of action whether in contract,
              tort (including, but not limited to, negligence) or otherwise
              exceed the greater of (a) the amount of fees earned by us in
              connection with your use of the Services during the three (3)
              month period immediately preceding the event giving rise to the
              claim for liability, or (b) $100. Some jurisdictions may not allow
              the exclusion of implied warranties in which case some of the
              above exclusions may not apply to all users.
            </p>
          </li>
          <li>
            <p>Indemnification</p>
            <p>
              You agree to release, indemnify, defend and hold the LiftRocket
              Parties harmless from and against any and all claims, liabilities,
              losses, damages, costs and expenses, including, without
              limitation, reasonable attorneys' fees, incurred by the LiftRocket
              Parties arising out of or relating to (a) your improper use of the
              Site or the Services; (b) your breach or alleged breach of this
              Agreement, the representations, warranties, and covenants you have
              made by agreeing to the Agreement of this Agreement, or applicable
              law; (c) your violation or alleged violation of any rights of a
              third party; or (d) any negligent acts, omissions, or willful
              misconduct by you. You shall cooperate as fully as reasonably
              required in the defense of any such claim. LiftRocket reserves the
              right, at its own expense, to assume the exclusive defense and
              control of any matter subject to indemnification by you. You agree
              not to settle any matter without the prior written consent of
              LiftRocket. This indemnification, defense and hold harmless
              obligation will survive this Agreement and the termination of your
              use of the Site or the Services.
            </p>
          </li>
          <li>
            <p>Dispute Resolution and Binding Arbitration</p>
            <p>
              YOU HAVE READ THIS PROVISION CAREFULLY AND UNDERSTAND THAT IT
              LIMITS YOUR RIGHTS IN THE EVENT OF A DISPUTE BETWEEN YOU AND US.
              YOU UNDERSTAND THAT YOU HAVE THE RIGHT TO REJECT THIS PROVISION AS
              PROVIDED IN SECTION 20.3 BELOW.
            </p>
            <p>18.1. Election to Arbitrate</p>
            <p>
              You and LiftRocket agree that the sole and exclusive forum and
              remedy for resolution of a Claim be final and binding arbitration
              pursuant to this section 20 (the "Arbitration Provision"), unless
              you opt out as provided in section 20.3 below. As used in this
              Arbitration Provision, "Claim" shall include any past, present, or
              future claim, dispute, or controversy involving you (or persons
              claiming through or connected with you), on the one hand, and us
              on the other hand, relating to or arising out of this Agreement,
              and/or the activities or relationships that involve, lead to, or
              result from this Agreement, such as the Site or the Services and
              including (except to the extent provided otherwise in the last
              sentence of Section 20.8 below) the validity or enforceability of
              this Arbitration Provision, any part thereof, or the entire
              Agreement. Claims are subject to arbitration regardless of whether
              they arise from contract; tort (intentional or otherwise); a
              constitution, statute, common law, or principles of equity; or
              otherwise. Claims include matters arising as initial claims,
              counter‐claims, cross-claims, third-party claims, or otherwise.
              Please note that you may continue to assert Claims in small claims
              court, if your Claims qualify and so long as the matter remains in
              such court and advances only on an individual (non-class,
              non-representative) basis. The scope of this Arbitration Provision
              is to be given the broadest possible interpretation that is
              enforceable.
            </p>
            <p>
              18.2. Applicability of the Federal Arbitration Act; Arbitrator's
              Powers.
            </p>
            <p>
              This Arbitration Provision is made pursuant to a transaction
              involving interstate commerce and shall be governed by and
              enforceable under the Federal Arbitration Act (the "FAA"). The
              arbitrator will apply substantive law consistent with the FAA and
              applicable statutes of limitations. The arbitrator may award
              damages or other types of relief permitted by applicable
              substantive law, subject to the limitations set forth in this
              Arbitration Provision. The arbitrator will not be bound by
              judicial rules of procedure and evidence that would apply in a
              court. The arbitrator shall take steps to reasonably protect
              confidential information.
            </p>
            <p>18.3. Opt-Out of Arbitration Provision.</p>
            <p>
              You may opt out of this Arbitration Provision for all purposes by
              sending an arbitration opt out notice to
              member.services@LiftRocket.com, 
              <strong>
                within 60 days of the date of your electronic acceptance of the
                Agreement of this Agreement
              </strong>
              . The opt out notice must clearly state that you are rejecting
              arbitration; identify the Agreement to which it applies by date;
              provide your name, address, and social security number; and be
              signed by you. You may send an opt-out notice in any manner you
              see fit as long as it is received at the specified address within
              the specified time. No other methods can be used to opt out of
              this Arbitration Provision. If the opt out notice is sent on your
              behalf by a third party, such third party must include evidence of
              his or her authority to submit the opt out notice on your behalf.
            </p>
            <p>18.4. Informal Dispute Resolution.</p>
            <p>
              If a Claim arises, our goal is to learn about and address your
              concerns and, if we are unable to do so to your satisfaction, to
              provide you with a neutral and cost effective means of resolving
              the dispute quickly. You agree that before filing any claim in
              arbitration, you may submit Claims by sending an email to
              member.services@LiftRocket.com at any time.
            </p>
            <p>18.5. Arbitration Procedures.</p>
            <p>
              The party initiating arbitration shall do so with the American
              Arbitration Association (the "AAA") or Judicial Alternatives and
              Mediation Services ("JAMS"). The arbitration shall be conducted
              according to, and the location of the arbitration shall be
              determined in accordance with, the rules and policies of the
              administrator selected, except to the extent the rules conflict
              with this Arbitration Provision or any countervailing law. If you
              have any questions concerning the AAA or would like to obtain a
              copy of the AAA arbitration rules, you may call 1(800) 778-7879 or
              visit the AAA's web site at: www.adr.org. If you have any
              questions concerning JAMS or would like to obtain a copy of the
              JAMS arbitration rules, you may call 1(800) 352-5267 or visit
              their web site at: www.jamsadr.com. In the case of a conflict
              between the rules and policies of the administrator and this
              Arbitration Provision, this Arbitration Provision shall control,
              subject to countervailing law, unless all parties to the
              arbitration consent to have the rules and policies of the
              administrator apply. The arbitration will be held in the United
              States county where you live or work, or any other location we
              agree to.
            </p>
            <p>18.6. Arbitration Fees.</p>
            <p>
              If we elect arbitration, we shall pay all the administrator's
              filing costs and administrative fees (other than hearing fees). If
              you elect arbitration, filing costs and administrative fees (other
              than hearing fees) shall be paid in accordance with the rules of
              the administrator selected, or in accordance with countervailing
              law if contrary to the administrator's rules. We shall pay the
              administrator's hearing fees for one full day of arbitration
              hearings. Fees for hearings that exceed one day will be paid by
              the party requesting the hearing, unless the administrator's rules
              or applicable law require otherwise, or you request that we pay
              them and we agree to do so. Each party shall bear the expense of
              its own attorneys' fees, except as otherwise provided by law. If a
              statute gives you the right to recover any of these fees, these
              statutory rights shall apply in the arbitration notwithstanding
              anything to the contrary herein.
            </p>
            <p>18.7. Appeals.</p>
            <p>
              Within 30 days of a final award by the arbitrator, any party may
              appeal the award for reconsideration by a three-arbitrator panel
              selected according to the rules of the arbitrator administrator.
              In the event of such an appeal, any opposing party may
              cross-appeal within 30 days after notice of the appeal. The panel
              will reconsider de novo all aspects of the initial award that are
              appealed. Costs and conduct of any appeal shall be governed by
              this Arbitration Provision and the administrator's rules, in the
              same way as the initial arbitration proceeding. Any award by the
              individual arbitrator that is not subject to appeal, and any panel
              award on appeal, shall be final and binding, except for any appeal
              right under the Federal Arbitration Act ("FAA"), and may be
              entered as a judgment in any court of competent jurisdiction.
            </p>
            <p>18.8. No Class Actions.</p>
            <p>
              NO ARBITRATION SHALL PROCEED ON A CLASS, REPRESENTATIVE, OR
              COLLECTIVE BASIS (INCLUDING AS PRIVATE ATTORNEY GENERAL ON BEHALF
              OF OTHERS), EVEN IF THE CLAIM OR CLAIMS THAT ARE THE SUBJECT OF
              THE ARBITRATION HAD PREVIOUSLY BEEN ASSERTED (OR COULD HAVE BEEN
              ASSERTED) IN A COURT AS CLASS REPRESENTATIVE, OR COLLECTIVE
              ACTIONS IN A COURT. Unless consented to in writing by all parties
              to the arbitration, no party to the arbitration may join,
              consolidate, or otherwise bring claims for or on behalf of two or
              more individuals or unrelated corporate entities in the same
              arbitration unless those persons are parties to a single
              transaction. Unless consented to in writing by all parties to the
              arbitration, an award in arbitration shall determine the rights
              and obligations of the named parties only, and only with respect
              to the claims in arbitration, and shall not (a) determine the
              rights, obligations, or interests of anyone other than a named
              party, or resolve any Claim of anyone other than a named party;
              nor (b) make an award for the benefit of, or against, anyone other
              than a named party. No administrator or arbitrator shall have the
              power or authority to waive, modify, or fail to enforce this
              Section 20.8, and any attempt to do so, whether by rule, policy,
              arbitration decision or otherwise, shall be invalid and
              unenforceable. Any challenge to the validity of this Section
              20.8 shall be determined exclusively by a court and not by the
              administrator or any arbitrator.
            </p>
            <p>18.9. Survival and Severability of Arbitration Provision.</p>
            <p>
              This Arbitration Provision shall survive the termination of this
              Agreement. If any portion of this Arbitration Provision other than
              section 20.8 is deemed invalid or unenforceable, the remaining
              portions of this Arbitration Provision shall nevertheless remain
              valid and in force. If there is a final judicial determination
              that applicable law precludes enforcement of this Arbitration
              Provision's limitations as to a particular claim for relief or
              particular term, then that claim (and only that claim) or that
              term (and only that term) must be severed from the Arbitration
              Provision and may be brought in court. If an arbitration is
              brought on a class, representative, or collective basis, and the
              limitations on such proceedings in section 20.8 are finally
              adjudicated pursuant to the last sentence of section 20.8 to be
              unenforceable, then no arbitration shall be had. In no event shall
              any invalidation be deemed to authorize an arbitrator to determine
              Claims or make awards beyond those authorized in this Arbitration
              Provision.
            </p>
            <p>18.10. Judicial Forum for Claims.</p>
            <p>
              Except as otherwise required by applicable law or governing loan
              agreement, in the event that this Arbitration Provision is found
              not to apply to you or your Claim, you and LiftRocket agree that
              any judicial proceeding (other than small claims actions) will be
              brought in the federal or state courts of New York, New York. Both
              you and LiftRocket consent to venue and personal jurisdiction
              there. We both agree to waive our right to a jury trial.
            </p>
            <p>18.11. WAIVER OF RIGHT TO LITIGATE.</p>
            <p>
              THE PARTIES ACKNOWLEDGE THAT THEY HAVE A RIGHT TO LITIGATE CLAIMS
              THROUGH A COURT BEFORE A JUDGE OR JURY, BUT WILL NOT HAVE THAT
              RIGHT IF ANY PARTY ELECTS ARBITRATION PURSUANT TO THIS ARBITRATION
              PROVISION. THE PARTIES HEREBY KNOWINGLY AND VOLUNTARILY WAIVE
              THEIR RIGHTS TO LITIGATE SUCH CLAIMS IN A COURT BEFORE A JUDGE OR
              JURY UPON ELECTION OF ARBITRATION BY ANY PARTY.
            </p>
          </li>
          <li>
            <p>Governing Law and Venue</p>
            <p>
              Except for Section 20 which is governed by the FAA, and the Credit
              Builder, this Agreement and all Claims are governed by the laws of
              the State of Connecticut, without regard to conflict-of-law rules.
            </p>
          </li>
          <li>
            <p>Support and Feedback</p>
            <p>
              Users may contact LiftRocket with regard to support for the Site
              or the Services by sending an email to 
              <u>member.services@LiftRocket.com</u>. We welcome feedback,
              comments and suggestions for improvements to the Site or the
              Services ("Feedback"). You can submit Feedback by emailing us at 
              <u>member.services@LiftRocket.com</u>. Any Feedback you submit to
              us will be considered non-confidential and non-proprietary to you.
              By submitting Feedback, you grant us a non-exclusive, worldwide,
              royalty-free, irrevocable, sub-licensable, perpetual license to
              use and publish those ideas and materials for any purpose, without
              compensation to you.
            </p>
          </li>
          <li>
            <p>
              Consent to Use Electronic Signatures and Electronic Communications
            </p>
            <p>
              <strong>"Communications"</strong> means any and all agreements,
              communications, disclosures, notices, records, documents and/or
              other items that we provide to you, or that you consent or agree
              to at our request.
            </p>
            <p>
              <strong>"Contacting Us"</strong> means emailing us at 
              <u>member.services@LiftRocket.com</u>.
            </p>
            <p>
              <strong>"Current Version"</strong> means a version of any
              particular software or application that is currently being
              supported by its publisher.
            </p>
            <p>
              <strong>"Electronic Communications"</strong> means any and all
              Communications that we provide to you in electronic form, and/or
              any and all Communications that you consent or agree to in
              electronic form, including but not limited to by means of your
              Electronic Signature.
            </p>
            <p>
              <strong>"Electronic Media"</strong> means the internet, a website,
              email, messaging services (including SMS and/or any other form of
              text messaging), software and/or applications (including
              applications for mobile devices, hand-held devices and/or other
              devices), and/or any other form of electronic media, whether
              existing now and/or that may exist in the future.
            </p>
            <p>
              <strong>"Electronic Product or Service"</strong> means each and
              every product and/or service we offer that you apply for, use or
              access using any Electronic Media.
            </p>
            <p>
              <strong>"Electronic Signature"</strong> means any means that is
              used to indicate Your consent and/or agreement by means of any
              Electronic Media, which may include, but is not limited to,
              clicking a button or checking a box on or taking any other action
              to indicate Your consent and/or agreement on or in any Electronic
              Media.
            </p>
            <p>21.1. Electronic Signatures and Electronic Communications.</p>
            <p>Subject to applicable law, you consent and agree:</p>
            <ul>
              <li>
                To use Electronic Signatures to indicate that you have read and
                understand, and that You agree and consent to this Agreement,
                our Privacy Policy, and any and all other Agreement and
                conditions and/or any other agreements that we may provide on
                any form of Electronic Media;
              </li>
              <li>
                That any and all Communications that we may send or provide to
                you (even Communications that we may otherwise be required to
                send or provide you in paper form) shall be in the form of
                Electronic Communications;
              </li>
              <li>
                Each Electronic Communication will be considered to be received
                by you at the time that we first attempt to send it to you, or
                if posted on the internet or on a Site, within 24 hours after
                the time that it is posted;
              </li>
              <li>
                To print and save and/or electronically store a copy of all
                Electronic Communications that we send to you;
              </li>
              <li>
                To notify us of any LiftRocket in Your email address or your
                mobile device number or other text message address by Contacting
                Us;
              </li>
              <li>
                That the foregoing consents and agreements will remain in effect
                unless and until you withdraw them in accordance with this
                Agreement;
              </li>
              <li>
                That you represent and warrant to us that you have read and
                understand this Consent to Use Electronic Signatures and
                Electronic Communications.
              </li>
            </ul>
            <p>21.2. Hardware and Software Requirements.</p>
            <p>
              In order to access and retain our Electronic Communications, you
              need to have, and you represent and warrant to us that you do
              have, and you consent and agree that at all times you will have,
              all of the following: (1) a valid email address; (2) a computer,
              laptop, tablet, smartphone or other device that is
              Internet-enabled and is connected to the internet: (3) a web
              browser that includes 128-bit encryption, such as a Current
              Version of Chrome, Internet Explorer, Firefox or Safari, with
              cookies enabled; (4) a Current Version of a program that
              accurately reads and displays PDF files, such as Adobe Acrobat
              Reader version 7 or higher; (5) a computer or device and an
              operating system capable of supporting all of the above; and (6)
              data storage to electronically save Communications or an installed
              printer to print them.
            </p>
            <p>21.3. Withdrawal of Consent.</p>
            <p>
              You may withdraw your consent to receive Electronic Communications
              by Contacting Us. Such withdrawal will be effective only after we
              have had a reasonable time period to process such withdrawal, and
              will not apply to Electronic Communications provided by us to you
              before the withdrawal of your consent becomes effective. If you
              withdraw your consent, then, except as may be expressly provided
              otherwise in this Agreement, (a) we will terminate your access to,
              and your ability to use, the Service, and (b) we will close any
              account that you may have with us and will return to you any
              balance that you may have therein.
            </p>
            <p>21.4. Changes.</p>
            <p>
              We reserve the right, in our sole discretion, (a) to communicate
              with you in paper form and/or (b) to discontinue to provide you
              with Electronic Communications, and/or (c) to terminate our
              Agreement and conditions with respect to Electronic Signatures and
              Electronic Communications. Subject to any applicable law, we may
              notify you of any such discontinuance, termination or LiftRocket
              by means of Electronic Communications, by updating this Agreement
              on the Site or the Services, or by delivering notice by email or
              text message, or by any other means of Electronic Communications
              that we may choose in our discretion.
            </p>
            <p>21.5. Requesting Paper Copies.</p>
            <p>
              You may request a paper copy of any Electronic Communication that
              we provided to you by Contacting Us. We will mail the paper copy
              to your mailing address that we have on file.
            </p>
            <p>
              21.6. Contacting Us Regarding Electronic Signatures and Electronic
              Communications.
            </p>
            <p>
              Please feel free to request information or ask questions regarding
              our policy on Electronic Signatures and Electronic Communications
              by Contacting Us.
            </p>
          </li>
          <li>
            <p>SMS Messaging</p>
            <p>
              When you provide us with your mobile phone number, you agree that
              LiftRocket and its affiliates may contact you at that number using
              autodialed or prerecorded message calls or text messages with
              service-related information such as alerts and transactional
              messages, or questions about the Site, Services or your LiftRocket
              User Account. We will not use autodialed or prerecorded SMS or
              texts to contact you for marketing or promotional purposes unless
              we receive your prior express written consent. You do not have to
              agree to receive marketing-related, autodialed or prerecorded SMS
              or texts in order to use and enjoy the Site or the Services. If
              you choose to receive marketing-related autodialed or prerecorded
              SMS or texts, you can later opt-out of such marketing-related
              messages in several ways, including through the Settings page of
              your LiftRocket User Account or by contacting us at
              member.services@LiftRocket.com. Standard telephone minute and text
              charges may apply to all SMS or text messages.
            </p>
            <p>
              You certify, warrant and represent that the telephone number you
              have provided to us is your contact number and not someone else's.
              You represent that you are permitted to receive calls and text
              messages at the telephone number you have provided to us. You
              agree to promptly alert us whenever you stop using a telephone
              number.
            </p>
            <p>
              We may modify or terminate our SMS messaging services from time to
              time, for any reason, and without notice, including the right to
              terminate SMS messaging with or without notice, without liability
              to you.
            </p>
          </li>
          <li>
            <p>Miscellaneous</p>
            <p>23.1. Severability.</p>
            <p>
              If any portion of this Agreement is deemed unlawful, void or
              unenforceable by any arbitrator or court of competent
              jurisdiction, this Agreement as a whole shall not be deemed
              unlawful, void or unenforceable, but only that portion of this
              Agreement that is unlawful, void or unenforceable shall be
              stricken from this Agreement.
            </p>
            <p>23.2. Survival.</p>
            <p>
              The following provisions of this Agreement shall survive
              termination of your use or access to the Site or the Services: the
              sections concerning Indemnification, Disclaimer of Warranties,
              Limitation of Liability, Dispute Resolution by Binding
              Arbitration, and Miscellaneous, and any other provision that by
              its terms survives termination of your use or access to the Site
              or the Services.
            </p>
            <p>23.3. Entire Agreement.</p>
            <p>
              This Agreement represents the entire understanding and agreement
              between you and LiftRocket regarding the subject matter of the
              same, and supersedes all other previous agreements.
            </p>
            <p>23.4. Assignment.</p>
            <p>
              You may not assign any rights hereunder without our prior written
              consent. LiftRocket may assign its rights or obligations pursuant
              to this Agreement. Nothing contained in this Agreement shall be
              construed to limit the actions or remedies available to LiftRocket
              with respect to any prohibited activity or conduct.
            </p>
            <p>23.5. Waiver.</p>
            <p>
              Non-enforcement of any term of this Agreement does not constitute
              consent or waiver, and LiftRocket reserves the right to enforce
              such term at its sole discretion. No waiver of any breach or
              default hereunder shall be deemed to be a waiver of any preceding
              or subsequent breach or default.
            </p>
            <p>23.6. No Joint Venture.</p>
            <p>
              No joint venture, partnership, employment, or agency relationship
              exists between you and LiftRocket as a result of this Agreement or
              your use of the Site or the Services.
            </p>
          </li>
          <li>
            <p>Contact Us</p>
            <p>
              If you have any questions, comments, or concerns regarding the
              Agreement, please contact us at 
              <u>member.services@LiftRocket.com</u>, or LiftRocket LLC, 1177
              High Ridge Road, Stamford, CT 06905
            </p>
          </li>
        </ol>
      </div>
    </div>
  </section>
  // </div>
);
