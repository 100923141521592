import { useState, useEffect, useRef } from "react";
import React from "react";

//Fulfills a request immediately and provides the data to the user
export const useRequest = <T>(fn: () => Promise<T>): [T | null, () => void, boolean] => {
  const [data, setData] = React.useState<T | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [refetchIndex, setRefetchIndex] = React.useState(0);
  useEffect(() => {
    let isMounted = true;
    fn().then(d => {
      if (!isMounted) return;
      setLoading(false);
      setData(d);
    });
    return () => {
      isMounted = false;
    };
  }, [fn, refetchIndex]);
  return [
    data,
    () => {
      setLoading(true);
      setRefetchIndex(i => i + 1);
    },
    loading
  ];
};

//TODO: Make this hook work
//Uses a request but waits until the first time the data is accessed to fulfill it
// const useDelayedRequest = <T>(fn: () => Promise<T>) => {};

//CREDIT TO MATTHEW DAVIS FOR THIS HOOK
export function useThrottle<T>(value: T, limit: number): T {
  const [throttledValue, setThrottledValue] = useState(value);
  const lastRan = useRef(Date.now());

  useEffect(() => {
    lastRan.current = Number(Date.now());
  }, [value]);

  useEffect(() => {
    const handler = setTimeout(function() {
      if (Date.now() - lastRan.current >= limit) {
        setThrottledValue(value);
      }
      lastRan.current = Date.now();
    }, limit - (Date.now() - lastRan.current));

    return () => {
      clearTimeout(handler);
    };
  }, [value, limit]);

  return throttledValue;
}
