import { DefaultTheme } from "styled-components";

export const breakPoints = {
  small: 744,
  medium: 1128,
  large: 1440,
};

export const theme: DefaultTheme = {
  bluegrey: "#4d6177",
  yellow: "#f3ca3e",
  orange: "#ff5f58",
  white: "#ffffff",
  whiteHover: "rgba(77,97,119,0.1)",

  breakPoints: {
    small: breakPoints.small + "px",
    medium: breakPoints.medium + "px",
    large: breakPoints.large + "px",
  },
};
