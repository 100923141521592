import styled from "styled-components";

export const FormStyle = styled.form`
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  div.select {
    select {
      background: none;
      border: 1px solid ${props => props.theme.bluegrey};
      color: ${props => props.theme.bluegrey};

      & option {
        color: black;
        margin: 0px;
        background: none;
      }
    }
  }

  & input[type="date"],
  & input[type="text"],
  & input[type="search"],
  & input[type="tel"],
  & input[type="number"],
  & input[type="email"],
  & input[type="password"],
  & input[type="username"] {
    background: none;
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid ${props => props.theme.bluegrey};
    color: ${props => props.theme.bluegrey};
    outline: none !important;
    width: 100%;

    ::placeholder {
      color: ${props => props.theme.bluegrey} !important;
      opacity: 0.5;
      font-family: Roboto;
      font-size: 14px;
      line-height: 24px;
    }
  }

  & textarea {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    min-height: 100px;
    border-radius: 5px;
    border: 1px solid ${props => props.theme.bluegrey}
    padding: 5px 10px;
    max-height: 500px;
  }

  button {
    width: 100%;
  }

  & input:focus,
  & select:focus,
  & option:focus,
  & select:focus,
  & textarea:focus {
    outline: none !important;
    box-shadow: none;
  }
`;

export const FormStyleWhiteText = styled.form`
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  div.select {
    select {
      background: none;
      border: 1px solid ${props => props.theme.white};
      color: ${props => props.theme.white};

      & option {
        color: black;
        margin: 0px;
        background: none;
      }
    }
  }

  & input[type="date"],
  & input[type="text"],
  & input[type="search"],
  & input[type="tel"],
  & input[type="number"],
  & input[type="email"],
  & input[type="password"],
  & input[type="username"] {
    background: none;
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid ${props => props.theme.white};
    color: ${props => props.theme.white};
    outline: none !important;
    width: 100%;

    ::placeholder {
      color: ${props => props.theme.white} !important;
      opacity: 0.5;
      font-family: Roboto;
      font-size: 14px;
      line-height: 24px;
    }
  }

  & textarea {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    min-height: 100px;
    border-radius: 5px;
    border: none;
    padding: 5px 10px;
    max-height: 500px;
  }

  button {
    width: 100%;
  }

  & input:focus,
  & select:focus,
  & option:focus,
  & select:focus,
  & textarea:focus {
    outline: none !important;
    box-shadow: none;
  }
`;

export const FormWrapper = styled(FormStyle)`
  text-align: center;
  width: 300px;

  & > img {
    margin-bottom: 25px;
  }
`;

export const FormWrapperWhiteText = styled(FormStyleWhiteText)`
  text-align: center;
  width: 300px;

  & > img {
    margin-bottom: 25px;
  }
`;
export const FormWrapperOrange = styled(FormStyle)`
  text-align: center;

  & > img {
    margin-bottom: 25px;
  }
`;
export const FormWrapperNoWidth = styled(FormStyle)`
  text-align: center;

  & > img {
    margin-bottom: 25px;
  }
`;
