import React from "react";
import { LegalPage } from "components/styled";
import { XLink, Footer } from "components/shared";
import { LegalSection } from "./terms-of-use-text";

export const TermsofUseAgreement = () => (
  <LegalPage>
    <XLink />
    <LegalSection />
    <Footer />
  </LegalPage>
);
