import React from "react";
import useForm, { FormContext } from "react-hook-form";
import {
  FormWrapper,
  FormWrapperWhiteText,
  FormWrapperOrange,
  FormStyle,
} from "components/styled";

export const Form: React.FC<{ onSubmit: (arg0: any) => void; args?: any }> = ({
  onSubmit,
  children,
  args,
}) => {
  const formContext = useForm({ mode: "onBlur", ...args });

  return (
    <FormContext {...formContext}>
      <FormWrapper onSubmit={formContext.handleSubmit(onSubmit)}>
        {children}
      </FormWrapper>
    </FormContext>
  );
};

export const FormWhiteText: React.FC<{
  onSubmit: (arg0: any) => void;
  args?: any;
}> = ({ onSubmit, children, args }) => {
  const formContext = useForm({ mode: "onBlur", ...args });

  return (
    <FormContext {...formContext}>
      <FormWrapperWhiteText onSubmit={formContext.handleSubmit(onSubmit)}>
        {children}
      </FormWrapperWhiteText>
    </FormContext>
  );
};

export const FormOrange: React.FC<{
  onSubmit: (arg0: any) => void;
  args?: any;
}> = ({ onSubmit, children, args }) => {
  const formContext = useForm({ mode: "onBlur", ...args });

  return (
    <FormContext {...formContext}>
      <FormWrapperOrange onSubmit={formContext.handleSubmit(onSubmit)}>
        {children}
      </FormWrapperOrange>
    </FormContext>
  );
};

export const FormWithoutWrapper: React.FC<{
  onSubmit: (arg0: any) => void;
  args?: any;
}> = ({ onSubmit, children, args }) => {
  const formContext = useForm({ mode: "onBlur", ...args });

  return (
    <FormContext {...formContext}>
      <FormStyle onSubmit={formContext.handleSubmit(onSubmit)}>
        {children}
      </FormStyle>
    </FormContext>
  );
};
