import React from "react";
import { LegalPage } from "components/styled";
import { SportFooter } from "components/shared";
import { PrivacyPolicySection } from "./privacy-policy-text";
import sportLogo from "../../../assets/svg/sportLogo.svg";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
export const SportNav = () => {
  const history = useHistory();
  const onSubmit = () => {
    history.push("/sportRegister");
  };
  return (
    <section className="section">
      <div className="sportNav">
        <div>
          <Link className="" to="/">
            <img src={sportLogo} alt="header rocket" className="sportLogo" />
          </Link>
        </div>
        <div className="sportNavBtnContainer">
          <button className="sportNavBtn2" onClick={() => onSubmit()}>
            Sign up
          </button>
         
        
        </div>
      </div>
    </section>
  );
};

export const PrivacyPolicyAgreement = () => (
  <LegalPage>

    <SportNav />
    <PrivacyPolicySection />
    <SportFooter />
  </LegalPage>
);
