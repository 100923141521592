import * as fields from "./form-fields";
import * as text from "./form-text";
import * as wrappers from "./form-wrappers";
import * as select from "./form-select";
import * as friendSearch from "./form-friend-search";

export const Form = {
  ...fields,
  ...text,
  ...wrappers,
  ...select,
  ...friendSearch,
};
