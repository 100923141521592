const msPerMinute = 60 * 1000;
const msPerHour = msPerMinute * 60;
const msPerDay = msPerHour * 24;
const msPerMonth = msPerDay * 30;
// const msPerYear = msPerDay * 365;

//https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString

export const formatDate = (utc: number) => new Date(utc * 1000).toDateString();

export const afterTodayTime = (utc: number) => {
  const now = Date.now();
  const time = utc * 1000;
  return time > now;
};

export const formatTime = (utc: number) => {
  const now = Date.now();
  const time = utc * 1000;

  return timeDifference(now, time);
};

export const formatMonthYear = (utc: number) => {
  const date = new Date(utc * 1000);
  return date.toLocaleDateString("en-US", { month: "long", year: "numeric" });
};

export const formatMonthDayYear = (utc: number) => {
  const date = new Date(utc * 1000);
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};

export const formatMonthDayYearTimeLocal = (utc: number) => {
  const date = new Date(utc * 1000);
  return (
    date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    }) +
    " " +
    date.toLocaleTimeString("en-US")
  );
};

export const formatMonthDay = (utc: number) => {
  const date = new Date(utc * 1000);
  return date.toLocaleDateString("en-US", { month: "short", day: "numeric" });
};

export const formatMonthDayYearTimeUTC = (utc: number) => {
  const date = new Date(utc * 1000);
  return (
    date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
      timeZone: "utc",
    }) +
    " " +
    date.toLocaleTimeString("en-US", { timeZone: "utc" })
  );
};

const timeDifference = (current: number, previous: number): string => {
  const elapsed = current - previous;

  if (elapsed < msPerMinute) {
    // const secondsAgo = Math.round(elapsed / 1000);
    // return secondsAgo > 30 ? +`${secondsAgo} seconds ago` :
    return "just now";
  } else if (elapsed < msPerHour) {
    const minutesAgo = Math.round(elapsed / msPerMinute);
    return `${minutesAgo} minute${minutesAgo > 1 ? "s" : ""} ago`;
  } else if (elapsed < msPerDay) {
    const hoursAgo = Math.round(elapsed / msPerHour);
    return `${hoursAgo} hour${hoursAgo > 1 ? "s" : ""} ago`;
  } else if (elapsed < msPerMonth) {
    const daysAgo = Math.round(elapsed / msPerDay);
    return daysAgo > 1 ? `${daysAgo} days ago` : "yesterday";
  } else return new Date(previous).toDateString();

  //   else if (elapsed < msPerYear) {
  //     return "approximately " + Math.round(elapsed / msPerMonth) + " months ago";
  //   } else {
  //     return "approximately " + Math.round(elapsed / msPerYear) + " years ago";
  //   }
};

export const formatDaysFromNow = (utc: number) => {
  const now = Date.now();
  const time = utc * 1000;

  const difference = time - now;

  if (difference < 0) return "0";
  /*new Date(time).toDateString();*/ else if (difference < msPerDay)
    return "today";
  else return (difference / msPerDay).toFixed(0);
};

export const formatMinCountDownFromNow = (utc: number) => {
  const now = Date.now();
  const time = utc * 1000;

  const difference = time - now;

  if (difference < 0) return "0";
  /*new Date(time).toDateString();*/ else if (difference < msPerMinute)
    return "0";
  else return (difference / msPerMinute).toFixed(0);
};
