import React, { useReducer, useContext } from "react";

const initialLiftBoosterState = {
  dataOne:null,
  dataTwo:null,
  dataThree:null,
  dataFour: null,
}

const LiftBoosterContext = React.createContext<[Data, Dispatch] | undefined>(undefined);
type Data = { dataOne:any, dataTwo:any, dataThree:any, dataFour:any};
type Action = { type: "setDataOne"; payload: { data: any }}
            | { type: "setDataTwo"; payload: { data: any }}
            | { type: "setDataThree"; payload: { data: any }}
            | { type: "setDataFour"; payload: { data: any }}
            | { type: "reset";}

type Dispatch = (action: Action) => void;

const LiftBoosterReducer = (state: Data, action: Action) => {
  switch (action.type) {
    case "setDataOne":
        return {...state, dataOne: action.payload.data};
    case "setDataTwo":
      return {...state, dataTwo: action.payload.data};
    case "setDataThree":
      return {...state, dataThree: action.payload.data};
    case "setDataFour":
      return {...state, dataFour: action.payload.data};
    case "reset":
      return {...initialLiftBoosterState};
    default:
      throw new Error(`unknown action ${action}`);
  }
};

export const LiftBoosterProvider: React.FC = ({ children }) => {
  const [location, dispatch] = useReducer(LiftBoosterReducer, initialLiftBoosterState);
  return <LiftBoosterContext.Provider value={[location, dispatch]}>{children}</LiftBoosterContext.Provider>;
};

export const useLiftBoosterReducer = () => {
  const context = useContext(LiftBoosterContext);
  if (context === undefined) throw new Error("lift booster context is undefined");
  return context;
};