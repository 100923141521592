import React from "react";

import logoStandard from "assets/svg/liftRocketLogo.svg";
import facebook from "assets/svg/facebook-footer2.svg";
import twitter from "assets/svg/twitter-footer2.svg";
import instagram from "assets/svg/instagram-footer2.svg";
import footerDesktop from "assets/svg/footerDesktop.svg";
import footerMobile from "assets/svg/footerMobile3.svg";
// import logo from "assets/svg/liftBoosterLogo.svg";
import { config } from "model";
import { Link } from "react-router-dom";

export const LiftBoosterNav =()=>{
  return(
  <div className="liftBoosterLogo">
    {/* <img
      src={logo}
      className=""
      alt="header rocket"
    /> */}
  </div>
  )
}
export const LiftStripeInfoNav =()=>{
  return(

  <div className="liftBoosterLogo">
  <Link to="/liftDashboard">
  <div className="arrowBack">
   <img
                className=""
                src={`${process.env.PUBLIC_URL}/svg/leftArrow.svg`}
                alt="back"
              />
  </div>
  </Link>
  
   
  </div>
  )
}
export const Footer2 = () => {
  return (
    <>
      <div className="has-background-white">
        <div className="liftRocketBody">
          <div className="footer footerFont">
            <div className="columns">
              <div className="column is-one-quarter">
                <div className="">
                  <div className="footerImageContainer">
                    <a href={config.mainUrl} className="column footerItemImage">
                      <img
                        src={logoStandard}
                        className="footerLogo"
                        alt="header rocket"
                      />
                    </a>
                    <div className="footerSocialContainer">
                      <a
                        href="https://www.facebook.com/liftrockett/"
                        className="footerSocial"
                      >
                        <img src={facebook} alt="facebook" />
                      </a>
                      <a
                        href="https://twitter.com/liftrocket?lang=en"
                        className="footerSocial"
                      >
                        <img src={twitter} alt="twitter" />
                      </a>
                      <a
                        href="https://www.instagram.com/liftrocket/"
                        className="footerSocial"
                      >
                        <img src={instagram} alt="instagram" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-three-quarter">
                <div className="footerContent">
                  <div className="footerContentItem">
                    <div className="flexCol">
                      <a href={`${config.mainUrl}/ourstory`}>ABOUT US</a>
                      <a href={`${config.mainUrl}/faq`}>FAQS</a>
                      <a href={`${config.mainUrl}/blog`}>BLOG</a>
                    </div>
                  </div>
                  <div className="footerContentItem">
                    <div className="flexCol">
                      <div className="">QUESTIONS?</div>
                      <a href="mailto:member.services@liftrocket.com">
                        CONTACT US
                      </a>
                      <a href={`/requestLift/1`}>REQUEST LIFT</a>
                    </div>
                  </div>
                  <div className="footerContentItem mobileFooterMT">
                    <div className="flexCol">
                      <a href={`/termsofuse`}>TERMS {"&"} CONDITIONS</a>
                      <a href={`/privacypolicy`}>PRIVACY POLICY</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export const Footer = () => {
  return (
    <>
      <div className="has-background-primary has-text-white">
        <div className="footer footerFont">
          <div className="columns">
            <div className="column is-one-quarter">
              <div className="">
                <div className="footerImageContainer">
                  <a href={`${config.mainUrl}`} className="column footerItemImage">
                    <img
                      src={logoStandard}
                      className="footerLogo"
                      alt="header rocket"
                    />
                  </a>
                  <div className="footerSocialContainer">
                    <a href="https://www.facebook.com/liftrockett/" className="footerSocial">
                      <img
                        src={facebook}
                        alt="facebook"
                      />
                    </a>
                    <a href="https://twitter.com/liftrocket?lang=en" className="footerSocial">
                      <img
                        src={twitter}
                        alt="twitter"
                      />
                    </a>
                    <a href="https://www.instagram.com/liftrocket/" className="footerSocial">
                      <img
                        src={instagram}
                        alt="instagram"
                      />
                    </a>
                  </div>

                </div>
               
              </div>

            </div>
            <div className="column is-three-quarter pt-0px">
              <div className="shortWhiteHorizontalLine2Container"><div className="shortWhiteHorizontalLine2"></div></div>
              
              
              <div className="footerContent">
                  <div className="footerContentItem">
                    <div className="flexCol">
                      <div className="shortWhiteHorizontalLine"></div>
                      <a id="footerLink" href={`${config.frontendUrl}/requestLift/1`}>REQUEST A LIFT</a>
                      <a id="footerLink" href={`${config.mainUrl}/ourstory`}>ABOUT US</a>
                      <a id="footerLink" href={`${config.mainUrl}/faq`} >FAQS</a>
                      {/* <Link to={"/blog"}>BLOGS</Link> */}
                    </div>
                  </div>
                  <div className="footerContentItem">
                    <div className="flexCol">
                    <div className="shortWhiteHorizontalLine"></div>

                      <a id="footerLink" href={`${config.mainUrl}/ourstory`} >TERMS {"&"} CONDITIONS</a>
                      <a id="footerLink" href={`${config.mainUrl}/ourstory`} >PRIVACY POLICY</a>
                      
                      <a id="footerLink" href="mailto:member.services@liftrocket.com">CONTACT US</a>
                      
                    </div>
                  </div>
                  

              
                
              </div>
            </div>
          </div>

          
        </div>
      </div>
    </>
  );
};
export const SportFooter = () => {



return(<div className="sportFooterBox">
  <div className="sportFooter">
    <img
      src={footerDesktop}
      className="desktopFlex"
      alt="header rocket"
    />
    <img
      src={footerMobile}
      className="mobileFlex"
      alt="header rocket"
    />

  </div>
  <div className="sportFooterText">
    <div>© LiftRocket, LLC. </div>
    <Link className="sportPrivatePolicy" to="/privacypolicy">Privacy Policy</Link>
  </div>
  
</div>)}