import { useCallback } from "react";
import { httpPost, httpGet } from "utilities";
import { API } from "model/interfaces";
import { useRequest } from "./hooks";

// type ApiHook<T> = [T | undefined, () => void, React.Dispatch<React.SetStateAction<T | undefined>>];

//TODO: cancel api call when component unmounts
// export const useApi = <T extends API>(fn: () => Promise<T>): ApiHook<T> => {
//   const [value, setValue] = useState<T>();
//   const isMounted = useIsMounted();

//   const updateValue = () => {
//     fn().then(r => {
//       if (isMounted.current) setValue(r);
//     });
//   };
//   useEffect(updateValue, []);
//   return [value, updateValue, setValue];
// };

const useApi = <T extends API>(fn: () => Promise<T>) => {
  const callback = useCallback(fn, []);
  return useRequest<T>(callback);
};

export const useApiPostHelpers = <T extends API>(route: string, postBody: any) =>
  useApi<T>(() => httpPost(route, postBody));

export const useApiGetHelpers = <T extends API>(route: string) =>
  useApi<T>(() => httpGet<T>(route));

export const useApiPost = <T extends API>(route: string, postBody: any) =>
  useApiPostHelpers<T>(route, postBody)[0];

export const useApiGet = <T extends API>(route: string) => useApiGetHelpers<T>(route)[0];
