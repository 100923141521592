import React, { useReducer, useContext } from "react";

const initialLiftRequestState = {
  dataOne:null,
  dataTwo:null,
  dataThree:null,
  dataFour: null,
  dataFive: null,
  dataSix: null,
  dataSeven: null,
  dataEight: null,
}

const LiftRequestContext = React.createContext<[Data, Dispatch] | undefined>(undefined);
type Data = { dataOne:any, dataTwo:any, dataThree:any, dataFour:any, dataFive:any, dataSix:any,dataSeven:any,dataEight:any};
type Action = { type: "setDataOne"; payload: { data: any }}
            | { type: "setDataTwo"; payload: { data: any }}
            | { type: "setDataThree"; payload: { data: any }}
            | { type: "setDataFour"; payload: { data: any }}
            | { type: "setDataFive"; payload: { data: any }}
            | { type: "setDataSix"; payload: { data: any }}
            | { type: "setDataSeven"; payload: { data: any }}
            | { type: "setDataEight"; payload: { data: any }}
            | { type: "reset";}

type Dispatch = (action: Action) => void;

const LiftRequestReducer = (state: Data, action: Action) => {
  switch (action.type) {
    case "setDataOne":
        return {...state, dataOne: action.payload.data};
    case "setDataTwo":
      return {...state, dataTwo: action.payload.data};
    case "setDataThree":
      return {...state, dataThree: action.payload.data};
    case "setDataFour":
      return {...state, dataFour: action.payload.data};
    case "setDataFive":
      return {...state, dataFive: action.payload.data};
    case "setDataSix":
      return {...state, dataSix: action.payload.data};
    case "setDataSeven":
      return {...state, dataSeven: action.payload.data};
    case "setDataEight":
      return {...state, dataEight: action.payload.data};
    case "reset":
      return {...initialLiftRequestState};
    default:
      throw new Error(`unknown action ${action}`);
  }
};

export const LiftRequestProvider: React.FC = ({ children }) => {
  const [location, dispatch] = useReducer(LiftRequestReducer, initialLiftRequestState);
  return <LiftRequestContext.Provider value={[location, dispatch]}>{children}</LiftRequestContext.Provider>;
};

export const useLiftRequestReducer = () => {
  const context = useContext(LiftRequestContext);
  if (context === undefined) throw new Error("lift request context is undefined");
  return context;
};