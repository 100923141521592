import React from "react";
import { Navbar, Footer } from "components/shared";
import { FlexCenter, BulmaPageLight } from "components/styled";

export const NotFound = () => (
  <BulmaPageLight>
    <Navbar absolute dark />
    <FlexCenter className="has-text-primary content has-background-white">
      <img
        src={`${process.env.PUBLIC_URL}/svg/rocket_blue.svg`}
        alt=""
        style={{ width: 200 }}
      />
      <h2 className="subtitle is-4">404 Page not found</h2>
      <code>{window.location.href}</code>
    </FlexCenter>
    <Footer />
  </BulmaPageLight>
);
