import styled from "styled-components";

export const PageBase = styled.div`
  min-height: 100vh;
  width: 100vw;
`;

export const FlexCenter = styled(PageBase)`
  background-color: ${props => props.theme.bluegrey};
  padding: 100px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FlexCenterWhiteBackground = styled(PageBase)`
  background-color: ${props => props.theme.white};
  padding: 100px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LegalPage = styled(PageBase)`
  color: ${props => props.theme.bluegrey};
  background-color: ${props => props.theme.white};
`;

export const BulmaPageDark = styled(PageBase).attrs({
  className: "has-background-primary has-text-white",
})``;

export const BulmaPageLight = styled(PageBase).attrs({
  className: "has-background-white has-text-primary",
})``;
