import React from "react";
import Responsive, { MediaQueryProps } from "react-responsive";
import { breakPoints } from "components/styled/theme";

export const Desktop = (props?: MediaQueryProps) => (
  <Responsive {...props} minWidth={breakPoints.small + 1} />
);

export const DesktopNoTablet = (props?: MediaQueryProps) => (
  <Responsive {...props} minWidth={breakPoints.medium} />
);

export const Tablet = (props?: MediaQueryProps) => (
  <Responsive
    {...props}
    minWidth={breakPoints.small}
    maxWidth={breakPoints.medium}
  />
);
export const Mobile = (props?: MediaQueryProps) => (
  <Responsive {...props} maxWidth={breakPoints.small} />
);
export const BreakFor1000 = (props?: MediaQueryProps) => (
  <Responsive {...props} maxWidth={1000} />
);
