import { config } from "model";
import { devlog, authToken } from ".";

export async function httpPost<T>(url: string, postBody: any): Promise<T> {
  return fetchAndMap("POST", config.baseurl + url, JSON.stringify(postBody)).then(r => r as T);
}

export async function httpGet<T>(url: string, param?: string): Promise<T> {
  return fetchAndMap("GET", config.baseurl + url).then(r => r as T);
}

export async function fetchAndMap(method: string, url: string, body?: string) {
  return fetch(url, {
    method: method,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: authToken() || ""
    },
    body: body
  })
    .then(r => r.json())
    .then(r => {
      devlog(
        url.replace(config.baseurl, "").replace("/api/v1/", ""),
        `[${method}]`,
        body
          ? {
              body: body,
              response: r
            }
          : { response: r }
      );
      return r;
    })
    .catch(r => {
      devlog(r);
      return { status: 404, statusText: "Unable to connect to the server" };
    });
}
