import React, { useReducer, useContext } from "react";

const CartContext = React.createContext<[Cart, Dispatch] | undefined>(undefined);

type Dispatch = (action: Action) => void;
type Item = { name: string; price: number; apiUrl: string; apiPost: { method: string } & any };
type Cart = { items: Item[]; total: number };
type Action =
  | { type: "additem"; payload: { item: Item } }
  | { type: "updateItem" }
  | { type: "removeItem"; payload: { index: number } }
  | { type: "clear" };

const CartReducer = (state: Cart, action: Action) => {
  switch (action.type) {
    case "additem":
      // const addedItems = state.items.concat(action.payload.item);
      const addedItems = [action.payload.item];
      return {
        ...state,
        //for now: cart only holds one item. Update this line to have cart hold more
        items: addedItems,
        total: addedItems.reduce((acc, item) => (acc += item.price), 0)
      };
    case "updateItem":
      return {
        ...state
        // items: state.items.map((item, index) =>
        //   index === action.payload.index ? action.payload.item : item
        // )
      };
    case "removeItem":
      const removedItems = state.items.filter((item, index) => index !== action.payload.index);
      return {
        ...state,
        items: removedItems,
        total: removedItems.reduce((acc, item) => (acc += item.price), 0)
      };
    case "clear":
      return {
        items: new Array<Item>(0),
        total: 0
      };
    default:
      throw new Error(`unknown action ${action}`);
  }
};

export const CartProvider: React.FC = ({ children }) => {
  const [cart, dispatch] = useReducer(CartReducer, {
    items: new Array<Item>(0),
    total: 0
  });
  return <CartContext.Provider value={[cart, dispatch]}>{children}</CartContext.Provider>;
};

export const useCartReducer = () => {
  const context = useContext(CartContext);
  if (context === undefined) throw new Error("cart context is undefined");
  return context;
};
